import { useIntl } from "react-intl";
import * as yup from "yup";
import "yup-phone-lite";

export const useResetPasswordSchema = () => {

  const intl = useIntl()
   const schema = yup.object({
    password: yup.string().required(
      intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.PASSWORD_REQUIRED'})
    ).min(4),
  confirmPassword: yup.string()
    .required(intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.CONFIRM_PASS'}))
    .oneOf([yup.ref('password'), null], intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.PASSWORD_MATCH'}))
  }).required();


  return { schema }
}