export const FREE = 'FREE'
export const RECOMMENDED = 'RECOMMENDED'
export const WELCOME_PACKAGE = 'WELCOME_PACKAGE'

const FREE_PERKS = [
  { cancelIcon: false, key: 'LIMITED_CONTACTS'},
  { cancelIcon: false, key: 'EMAIL_NOTIFICATION'},
  { cancelIcon: true, key: 'RANDOM_MARKETING'},
  { cancelIcon: true, key: 'CREDIBILITY'},
  { cancelIcon: true, key: 'FEATURED_SEARCH'},
  ]

const RECOMMENDED_PERKS = [
  { cancelIcon: false, key: 'UNLIMITED_CONTACTS'},
  { cancelIcon: false, key: 'EMAIL_NOTIFICATION'},
  { cancelIcon: false, key: 'RANDOM_MARKETING'},
    { cancelIcon: false, key: 'CREDIBILITY'},
    { cancelIcon: false, key: 'FEATURED_SEARCH'},
  ]

  const WELCOME_PACK_PERKS = [
    { cancelIcon: false, key: 'LIMITED_CONTACTS'},
    { cancelIcon: false, key: 'EMAIL_NOTIFICATION'},
    { cancelIcon: false, key: 'RANDOM_MARKETING'},
    { cancelIcon: true, key: 'CREDIBILITY'},
    { cancelIcon: true, key: 'FEATURED_SEARCH'},
    ]
const RECOMMENDED_STEPS = ['STEP_1', 'STEP_2', 'STEP_3', 'STEP_4', 'STEP_5']

const FREE_STEPS = ['STEP_1']
const WELCOME_PACKAGE_STEPS = ['STEP_1', 'STEP_2']

export const PROMOTION_CONFIG = [
  {
    type: WELCOME_PACKAGE,
    perks: WELCOME_PACK_PERKS,
    steps: WELCOME_PACKAGE_STEPS
  },
  {
    type: FREE,
    perks: FREE_PERKS,
    steps: FREE_STEPS
  },
  {
    type: RECOMMENDED,
    perks: RECOMMENDED_PERKS,
    steps: RECOMMENDED_STEPS
  }
]