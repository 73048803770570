
import { Gallery, Typography } from "../../../component";
import { useVendorContext } from "../context";

export const VendorGallery = () => { 
  
 const { vendor } = useVendorContext()

  return (
    <div className="mt-10 relative">      
      <div className="flex flex-col md:items-start items-center justify-center">
        <Typography
          translationKey="VENDOR.GALLERY.MESSAGE"
          variant='label'
          className="text-center md:text-left md:text-xl"
        />  
      </div>
      <Gallery
        showHeader={false} 
        vendorImages={vendor?.images}
        description={
          <Typography
              translationKey="VENDOR.ADVERT.UPLOAD_IMAGES"
              variant='label'
          />
        }
      />
    </div>
  )
}