import { useQueryClient } from "react-query";
import { Grid, Loader } from "../../../component";
import { USER_ACTION_TYPES, toastUtil } from "../../../utils";
import { Header } from "../header"
import { COLUMNS } from "./constant";
import { useAdminSendReviewMailMutation, useGetUsers } from "./query";
import { useAdminResetUserOrVendorMutation } from "../query";
import { getActionTypes } from "./utils";

export const Users = () => {
  const { data, isLoading } = useGetUsers();
  const queryClient = useQueryClient()
  const { mutate, isLoading: isSendingEmail } = useAdminSendReviewMailMutation()
  const { mutate: resetUserOrVendor, isLoading: isDeactivating } = useAdminResetUserOrVendorMutation()

if (isLoading) {
  return <div className="w-full flex justify-center items-center"><Loader/></div>
}

const sendMail = (selectedValue) => {
  const vendorId = selectedValue.data.vendor.id
  const userId = selectedValue.data.id

  
   mutate({ vendorId, userId }, {
    onSuccess: (response) => {

      queryClient.invalidateQueries({queryKey: ['users']})
      toastUtil({ message: response.message, position: 'top-center', theme: 'success' })
    },
    onError: (error) => toastUtil({ message: error.response.data.message, position: 'top-center', theme: 'failure' })

  })
}

const resetUserOrVendorFunc = (selectedValue) => {
  resetUserOrVendor({ id: selectedValue.data.id, type: 'user', isDeactivating: !selectedValue.data.deactivated })
}

const handleSaveConfig = {
  [USER_ACTION_TYPES.sendReviewMail]: sendMail,
  deactivate: resetUserOrVendorFunc,
  reactivate: resetUserOrVendorFunc
}

const handleSave = (selectedValue) => {
  handleSaveConfig[selectedValue.value](selectedValue)
}

return <div className="container mx-auto mt-12">
  <Header text="ADMIN.USERS.LABEL"/>
  <Grid
    className="mt-3"
    columns={COLUMNS}
    onSave={handleSave}
    contentData={data.users}
    isLoading={isSendingEmail || isDeactivating}
    getActionTypes={getActionTypes}
  />
</div>
  }