import { Ratings } from "../../../component";
import { useVendorContext } from "../context";

export const VendorReviews = () => { 
const { vendor } = useVendorContext()

  return (
    <div className="mt-10">      
      <Ratings showAverageRatingHeader showHeader={false} averageRating={vendor?.averageRating} description={{}} vendorReviews={vendor?.vendorReviews}/>
    </div>
  )
}