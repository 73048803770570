import classNames from "classnames"
import { AlertCircle, CheckSquare, Info } from "react-feather"
import { Typography } from "../typography";


const alertType = {
  info: {
    className: 'bg-gray-600',
    icon: Info,
    textColor: 'text-white',
    iconColor: 'text-gold-300'
  },
  warning: {
    className: 'bg-red-600',
    icon: AlertCircle,
    textColor: 'text-white',
    iconColor: 'text-white'
  },
  success: {
    className: 'bg-green-400',
    icon: CheckSquare,
    textColor: 'text-white',
    iconColor: 'text-white'
  },
}
export const Alert = ({ message, type, className, iconSize, action }) => {
  const config = alertType[type];
  const Icon = config.icon
  return (
     <div className={classNames("rounded-lg flex px-4 items-center", config.className, className)}>
          <div><Icon size={iconSize ?? 40} className={classNames("mr-3", config.iconColor)}/></div>
        <Typography
          translationKey={message}
          className={classNames('py-2 md:text-sm text-xs', config.textColor)}
          variant='span'
          values={{ action }}
        />
   </div>
  )
}