
import { forwardRef } from "react"
import { Card, Typography } from "../../component"

export const VendorSection = forwardRef((props, ref) => {
  const { vendors } = props

  return <div className="h-full py-8 flex-col items-center flex mt-6" ref={ref}>
    <Typography
      translationKey="HOME.TOP_VENDORS"
      variant="h1"
      className="text-2xl md:text-5xl text-center mb-4 md:mb-2 font-black"

    />
    <Typography
      translationKey="HOME.TOP_VENDORS_MESSAGE"
      variant="label"
      className="md:my-8 text-md md:w-[50%] md:px-0 px-8 text-center"
    />

    <div className="grid md:grid-cols-2 md:grid-flow-row w-full md:px-0 mt-6 px-4 md:w-auto gap-6 md:gap-16 max-w-[1200px]">
      {
        vendors.map(vendor => <Card key={vendor.id} 
            showAllServices 
            showDescription 
            vendor={vendor}
            />)
      }
    </div>
  </div>
})