import { useMutation } from "react-query";
import { apiRoutes, axiosInstance } from "../../../api";


export const useContactVendorMutation = () => {

  return useMutation(async (details) => {
    const { data } = await axiosInstance.post(apiRoutes.contactVendor, {...details})
      return data;
    },
  )
}