
export const COLUMNS = [
    { name: 'Id', accessor: 'id'},
    { name: 'Type', accessor: 'type'},
    { name: 'Vendor Name', accessor: 'vendorName'},
    { name: 'vendor Id', accessor: 'vendorId'},
    { name: 'Start Date', accessor: 'endDate'},
    { name: 'End Date', accessor: 'startDate'},
    { name: 'Active', accessor: 'isActive'},
    { name: 'Created', accessor: 'createdAt'},
    { name: 'Updated', accessor: 'updatedAt'},
    ]

export const ACTION_TYPES = [
    { label: 'End promotion', value: 0 },
  ]