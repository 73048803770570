export const ROUTE_LABELS = {
  HOME: 'Home',
  ABOUT: 'About',
  PRIVACY: 'Privacy',
  TERMS: 'Terms',
  VENDORS: 'Vendors',
  ADVERTS: 'Adverts',
  CONTACT: 'Contact us',
  ADD_LISTING: 'List your business',
  LOGIN: 'Login',
  ADMIN: {
    DASHBOARD: "Dashboard",
    PROMOTIONS: "Promotions",
    VENDORS: "Vendors",
    USERS: "Users"
  },
  VENDOR: {
    DASHBOARD: "Profile",
    GALLERY: 'Gallery',
    REVIEWS: 'Reviews',
    PROFILE: "Profile",
    LOGOUT: "Logout",
    PROMOTE: "Promote",
    CLIENTS: 'Clients'
  }
  
}
export const ROUTE_PATHS = {
  HOME: '',
  ABOUT: 'about',
  PRIVACY: 'privacy',
  TERMS: 'terms-and-services',
  LISTINGS: 'vendors',
  LISTINGS_DETAILS: 'vendors/:vendorSlug',
  ADVERTS: 'adverts',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password/:uniqueLink',
  CONTACT: 'contact-us',
  ADD_LISTING: 'add-business',
  VERIFY_ACCOUNT: 'verify-account',
  REVIEW_VENDOR: 'reviews/:slug/:reviewLink',
  LOGIN: 'login',
  ADMIN: {
    HOME: '',
    DASHBOARD: 'admin/dashboard',
    VENDORS: 'admin/vendors',
    USERS: 'admin/users',
    PROMOTIONS: 'admin/promotions',
  },
  VENDOR: {
    HOME: '',
    DASHBOARD: 'vendor/dashboard', // goes to the profile page
    PROMOTE: 'vendor/promote',
    GALLERY: 'vendor/gallery',
    REVIEWS: 'vendor/reviews',
  }
}

export const PROTECTED_ROUTES = [
  'vendor/dashboard', 'vendor/promote'
]

export const MAX_FILE_SIZE = 10485760

export const PAGINATION_ITEMS_PER_PAGE = 10