import React, { useEffect, useState } from 'react';
import { Button, Typography } from '..';
import fallbackImage from '../../assets/images/no-image.jpg'
import { openInputFile, toastUtil } from '../../utils';
import { usePostImage } from './query';
import { useAuth } from '../../core';

const Description = () => {
  return (
    <>
      <div className='flex flex-col'>
        <Typography
        translationKey='VENDOR.NO_IMAGE_IN_GALLERY'
        className='p-4 text-md'
        variant='label'
        />
        <Typography
        translationKey='VENDOR.NO_IMAGE_MESSAGE_TWO'
        className='px-4 text-md'
        variant='label'
        />
      </div>
    </>
  )
}

export const Gallery = ({ vendorImages = [], showHeader = true, description, isPreviewing = false }) => {
  const [images, setImages] = useState([])
  const [currentImage, setCurrentImage] = useState('')
  const [currentPublicId, setcurrentPublicId] = useState()
  const auth = useAuth()
  
  const { mutate, isLoading, invalidateCache } = usePostImage();
  const getStaticPublicUrl = () => Date.now() + Math.random() + fallbackImage;
  
  useEffect(() => {
    const parsedImages = vendorImages ? JSON.parse(vendorImages) : []

    if (!auth?.isAuthenticated || parsedImages.length === 4) {
       setImages(parsedImages)
       
       return
    } 

    if (parsedImages?.length < 4) {
      const filledImages = [...parsedImages ? [...parsedImages] : [], ...Array.from(new Array(parsedImages ? 4 - parsedImages?.length : 4))].map(img => !img ? {public_id: getStaticPublicUrl(), url: fallbackImage} : img)
      setImages(filledImages)
    }
  }, [vendorImages, setImages, auth?.isAuthenticated])

  
  const handleUpload = (index, publicId) => {
    const reader = new FileReader();
  
    openInputFile((file) => {
      // Set up the onloadend callback

      reader.onloadend = () => {

        const staticPublicUrl = getStaticPublicUrl()
        const base64String =  reader.result.replace('data:', '').replace(/^.+,/, '')
        const newImages = [...images.slice(0, index), {public_id: publicId, url: reader.result}, ...images.slice(index + 1)];
       setCurrentImage({public_id: staticPublicUrl, url: base64String})
       setcurrentPublicId(publicId)
       setImages(newImages)
      };
  
      // Read the current file
      reader.readAsDataURL(file);
    })
  }

  const handleSave = () => {
    mutate({ vendorId: auth?.vendorId, imageUrl: currentImage.url, publicUrl: currentPublicId}, {
      onSuccess: () => {
        setcurrentPublicId('')
        invalidateCache(auth?.vendorId)
        toastUtil({message: 'Image uploaded', theme: 'success', position: 'top-center'})

      },
      onError: () => toastUtil({message: 'Failed to upload', theme: 'failure', position: 'top-center'})
    })
  }

  return ( <>
        {showHeader && <div className='mt-2 md:mt-10'>
          <Typography
            translationKey='VENDOR.GALLERY.LABEL'
            className='p-4'
            variant='h3'
          />
          </div>} 

       

          {Boolean(!vendorImages?.length && !auth?.isAuthenticated) && <Description/>}
          {Boolean(auth?.isAuthenticated && images.length < 4) &&  description }

        { Boolean(images?.length) && 
            <div className='flex flex-wrap overflow-y-auto mt-6 justify-center'>
              { images.map((image, index) => {
                return (
                <div key={getStaticPublicUrl() + image.public_id} className='flex flex-col items-center mt-4 relative mr-10'>
                  <img className='max-h-[450px] w-auto lg:h-[250px] lg:max-h-[350px] lg:max-w-[400px] lg:w-[250px] rounded-2xl' src={image.url} alt='vendor'/>
                  {currentPublicId !== image.public_id && auth?.isAuthenticated && !isPreviewing &&  <Button onClick={() => handleUpload(index, image.public_id)} variant='secondary' size='md' className="mt-4 px-1" translationKey='VENDOR.GALLERY.ACTIONS.CHANGE'/>}
                  {image.url !== fallbackImage && currentPublicId === image.public_id && <div className='flex justify-around w-full sm:flex-row flex-col'>
                  <Button isLoading={isLoading} disabled={isLoading} onClick={handleSave} variant='primary' size='md' className="mt-4 px-1 flex-1 sm:mr-6" translationKey='VENDOR.GALLERY.ACTIONS.SAVE'/>
                  <Button disabled={isLoading} onClick={() => handleUpload(index)} variant='secondary' size='md' className="mt-4 px-1 flex-1" translationKey='VENDOR.GALLERY.ACTIONS.CHANGE'/>
                  </div>}
                </div>
                )
              })
            }

          </div>
        }
      </>
  )
}