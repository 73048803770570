import React from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../constants';


export const  Pagination = ({ itemsPerPage, getFilterValue, filters, totalCount }) => {
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const navigate = useNavigate()
  const handlePageClick = (event) => {
    getFilterValue({
      ...filters,
      page: event.selected + 1
    })
    
    navigate(`/${ROUTE_PATHS.LISTINGS}?page=${event.selected+1}`)
    window.scrollTo(0, 0)
  };
  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={pageCount}
        pageCount={pageCount}
        forcePage={filters.page - 1}
        previousLabel="< previous"
        className='w-[450px] px-3 bg-blue-600 md:h-10 h-8 items-center rounded-lg flex justify-between'
        pageClassName="md:w-8 w-7 md:py-2 py-1 flex items-center text-white justify-center rounded"
        activeClassName="bg-gray-300 text-black"
        previousClassName="text-white"
        nextClassName='text-white'
        disabledClassName="cursor-not-allowed text-gray-300"
        disabledLinkClassName="cursor-not-allowed text-gray-300"
      />
    </>
  );
}