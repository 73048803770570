import { Typography } from ".."


export const Description = ({ description }) => {
  return (
    <div className="drop-shadow-lg w-full">
      <Typography
        translationKey='VENDOR.DESCRIPTION'
        className='p-4'
        variant='h3'
      />
      <div className="border border-gray-200 h-[1px]"/>

      <div className="p-4 w-full md:min-h-max-content max-h-[400px] overflow-auto">
        <Typography
          variant='label'
          className='text-md font-thin break-words'
          translationKey="VENDOR.VENDOR_DESCRIPTION"
          values={{ description }}
        />
      </div>

    </div>
  ) 
}