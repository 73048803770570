
import { Fragment, useMemo } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion'
import { Briefcase, MapPin, Phone } from 'react-feather';
import { Tooltip } from 'react-tooltip'
import ReactStars from "react-rating-stars-component";
import { Link } from 'react-router-dom';
import { Typography } from '../typography';
import fallbackImage from '../../assets/images/no-image.jpg'
import { getDisplayAddress } from '../../utils';
import { ROUTE_PATHS } from '../../constants';
import { getIsVendorRecommended } from '../../pages/admin/vendors/utils/utils';

export const Card = ({
    vendor, 
    className, 
    imageContainerClassName,
    showDescription = false,
    detailsClassName,
    showAllServices = false
  }) => {
    const addressParts = vendor?.addressParts && JSON.parse(vendor.addressParts)
    const services = vendor?.services ?? []
    const isRecommended = useMemo(() => getIsVendorRecommended(vendor?.promotions), [vendor?.promotions])
    const displayAddress = useMemo(() => getDisplayAddress(addressParts), [addressParts])

    return (
    <motion.div exit={{ opacity: 1 }} initial={{ opacity: 1 }} whileHover={{ opacity: 0.8, transition: { duration: 0.3 } }}>
      <Link to={`/${ROUTE_PATHS.LISTINGS}/${vendor.slug}`} className={classNames("relative flex py-4 md:py-0 flex-col justify-center items-center md:flex-row md:justify-start rounded-lg rounded-t-l-0 md:h-[250px] md:max-w-[570px] drop-shadow bg-white md:p-1", className, {'md:p-1' : isRecommended})}>
        {isRecommended && <div className='absolute right-0 top-[-1px] rounded-tr-lg rounded-bl-lg bg-gold-300 opacity-75 w-[120px] h-[25px] flex justify-center items-center'>
          <div data-tooltip-content="This vendor is trusted to render good services." data-tooltip-id='recommended' data-tooltip-place='top'>
            <Typography translationKey='VENDOR.RECOMMENDED' variant='label' className='text-black'/>
            <Tooltip id='recommended'/>
          </div>
        </div>}
        <div className={classNames("h-full md:mr-4 md:mt-0 mt-6 md:w-[200px] flex justify-center items-center", imageContainerClassName)}>
          <img src={vendor?.logo ?? fallbackImage} alt="listing" className={classNames("md:rounded-lg rounded-full w-[100px]  sm:w-[150px]")}/>
        </div>
        <div className={classNames("bg-white justify-center flex-col flex md:mr-4 mt-2", detailsClassName)}>
          <h1 className='md:hidden font-black text-center text-md px-1 mt-2 md:text-lg'>{vendor.name}</h1>
          <h1 className='hidden md:block whitespace-break-spaces font-black text-md mt-2 md:text-lg'>{vendor.name}</h1>
          <div className='flex flex-col md:justify-start md:items-start justify-center items-center'>
              <div className='flex items-center mt-2'>
                  <Briefcase className='mr-2 text-blue-300' size={16}/>
                  {
                    services?.length > 1 ? (
                      showAllServices ? (
                        services.map(service => {
                          return (
                            <Fragment key={service}>
                              <Typography
                                variant='p'
                                className={classNames('font-black text-sm font-bold whitespace-nowrap', { 'text-xs md:text-sm': services?.length > 1})}
                                translationKey='VENDOR.SERVICE'
                                values={{service: service}}
                              />
                              <span className='last:hidden text-lg'>&#8729;</span>
                            </Fragment>
                          )
                        })
                    ) : (
                      <Typography
                        variant='p'
                        className='font-black md:text-sm font-bold text-xs p-1 rounded-lg bg-gold-100'
                        translationKey='VENDOR.SERVICES_COUNT'
                        values={{
                          service: services[0],
                          countOfServices: services?.length - 1
                        }}
                      />
                    )
                    ) : (
                      <Typography
                        variant='p'
                        className='font-black text-sm font-bold p-1 rounded-lg bg-gold-100'
                        translationKey='VENDOR.SERVICE'
                        values={{service: services[0]}}
                      />
                    )
                  }

              </div>
            <div className='flex items-center mt-2'>
              <MapPin size={12} className='md:hidden mr-2 text-blue-300'/>
              <MapPin size={16} className='md:block hidden mr-2 text-blue-300'/>
              <p className='text-sm md:text-md'>{displayAddress}</p>            
            </div>
            {Boolean(vendor.detailsRequestCount) && <div className='flex items-center mt-2'>
              <Phone size={12} className='md:hidden mr-2 text-blue-300'/>
              <Phone size={16} className='md:block hidden mr-2 text-blue-300'/>
              <Typography
                variant='label'
                translationKey='VENDOR.CONTACT_REQUESTS'
                className="text-sm md:text-md font-bold"
                values={{count: vendor.detailsRequestCount }}
              />
            </div>}
            {showDescription &&  <div className="mt-4 h-[2.5rem] md:w-auto overflow-hidden">
              <Typography
                variant='label'
                translationKey='VENDOR.VENDOR_DESCRIPTION'
                className="max-w-[200px] md:max-w-[300px] text-xs md:text-sm break-word text-center md:text-start line-clamp-2 md:text-ellipsis md:overflow-hidden"
                values={{description: vendor.description }}
              />
            </div>}
          </div>

            {
              Boolean(vendor.averageRating) && (
                <div className='flex items-center justify-center md:justify-start mt-4'>
                  <ReactStars
                    count={Number(vendor.averageRating)}
                    size={12}
                    color="#ffd700"
                    edit={false}
                  />
                  <p className='text-xs ml-2'>({vendor?.vendorReviews?.length} {vendor?.vendorReviews?.length > 1 ? 'reviews' : 'review'})</p>
                </div>
              )
            }
        </div>
      </Link>
    </motion.div>
  );
};
