import { useMutation } from "react-query";
import { apiRoutes, axiosInstance } from "../../../../api";


export const useAdminUpdateVendorMutation = () => {

  return useMutation(async (details) => {
      const { data } = await axiosInstance.post(apiRoutes.admin.recommendVendor, {...details})
      return data;
    },
  )
}