import { useMemo } from "react";
import { CheckCircle, Droplet, XCircle } from "react-feather";

import { Typography } from "../../../component"
import { FREE, PROMOTION_CONFIG, RECOMMENDED, WELCOME_PACKAGE } from "../constant";
import { useVendorContext } from "../context";
import { getWelcomePackageInfo } from "../../../utils";
import moment from "moment";
import classNames from "classnames";
import { getIsVendorRecommended } from "../../admin/vendors/utils/utils";

export const VendorPromotion = () => {
  const { vendor } = useVendorContext()
  const recommended = useMemo(() => getIsVendorRecommended(vendor?.promotions), [vendor.promotions])
  const planButtonStyle = 'w-full rounded-t-md flex justify-center h-9 bg-blue-600'
  const {endFreeDate, isWelcomePackageActive } = getWelcomePackageInfo(vendor.verifiedAt, recommended)
  const daysLeft = moment(endFreeDate).diff(moment(), 'days')

  return (
    <div className="w-full flex flex-col overflow-auto h-screen mt-10 mb-10 md:mb-0 pt-8">
      <Typography
        translationKey='PROMOTIONS.HEADER'
        variant='h3' 
        className='mb-4 w-full text-center'
        />
      <div className="flex lg:flex-row flex-wrap flex-col">
      <div className="mt-4 flex md:flex-row flex-col w-full justify-center gap-8 items-center">
        { (!isWelcomePackageActive ? PROMOTION_CONFIG.slice(1) : PROMOTION_CONFIG).map(({type, perks, steps}, index) => {        
          
          const currentPlan = {
            RECOMMENDED: recommended && type === RECOMMENDED,
            FREE: !Boolean(recommended) && type === FREE && !isWelcomePackageActive,
            WELCOME_PACKAGE: type === WELCOME_PACKAGE && isWelcomePackageActive && !Boolean(recommended)
          }
          return (
            <div key={index} className={classNames("hover:bg-blue-50 transition duration-300 ease border flex flex-col border-r-1 md:drop-shadow-none drop-shadow-lg w-[300px] md:rounded-none h-[750px]", {
              'bg-blue-100': currentPlan.FREE || currentPlan.RECOMMENDED || currentPlan.WELCOME_PACKAGE
            })}>
              <div className="m-2 flex flex-1 flex-col">
                <div className="flex justify-center items-center">
                  {type === FREE && <Droplet size={16} className="text-blue-300 mr-2" fill="#0B62DF"/>}
                  {type === RECOMMENDED && 
                    <>
                      <Droplet size={16} className="text-orange-300 mr-2" fill="#DF7616"/>
                      <Droplet size={16} className="text-orange-300 mr-2" fill="#DF7616"/>
                    </>
                    }
                  <Typography
                    translationKey={`PROMOTIONS.${type}.LABEL`}
                    variant='h3' 
                    className='text-center'/>
                </div>

                <div className="mt-4 flex flex-col justify-center items-center">
                  <Typography
                    translationKey={`PROMOTIONS.${type}.COST`}
                    variant='h3' 
                  />
                  {/* &nbsp; */}
                  {<Typography
                    translationKey={`PROMOTIONS.${type}.DURATION`}
                    className='text-[11px]'
                    values={{ daysLeft }}
                  />}
                </div>

                <div className="h-[1px] bg-gray-300 w-full mt-2"/>

                      {/* Perks */}

                <Typography
                  translationKey='PROMOTIONS.PERKS'
                  className="mt-2 px-2 text-blue-300"
                  variant="h5"
                />
                <div className="h-[250px]">
                    {
                      perks.map(({ cancelIcon, key}, index) => {
                        return <div key={key+index} className="my-3 w-full px-2 flex items-start">
                          <div>
                            {!cancelIcon&& <CheckCircle size={14} className="mr-2 text-green-300"/>}
                            {cancelIcon && <XCircle size={14} className="mr-2 text-red-300"/>}
                          </div>
                          {<Typography
                              translationKey={`PROMOTIONS.${type}.PERKS.${key}`}
                              className="text-[12px]"
                              variant='label'
                            />}
                        </div>
                      })
                    }
                </div>


                {/* Steps */}
                {Boolean(steps.length) && 
                  <>
                    <div className="h-[1px] bg-gray-300 w-full"/>
                    <Typography
                      translationKey='PROMOTIONS.REQUIRED_ACTIONS'
                      className="mt-2 px-2 text-blue-300"
                      variant="h6"
                    />
                  </>
                }


                    { steps.map(step => {
                    return (
                        <div key={step} className="mt-4 w-full px-2 flex items-start">
                          <div><CheckCircle size={14} className="mr-2 text-green-300"/></div>
                          <Typography
                              translationKey={`PROMOTIONS.${type}.STEPS.${step}`}
                              className="text-[12px]"
                              variant='label'
                            />
                        </div>
                      )
                    })  
                  }
                
              </div>
                {currentPlan.RECOMMENDED && 
                <div className={planButtonStyle}>
                    <Typography
                    translationKey='PROMOTIONS.CURRENT_PLAN'
                    values={{ endDate: moment(recommended.endDate).format("MMM Do, YYYY")}}
                    className="mt-2 text-white"
                    variant="label"
                    />
                   </div>
                  }

                {currentPlan.FREE &&
                 <div className={planButtonStyle}>

                    <Typography
                    translationKey='PROMOTIONS.CURRENT_FREE_PLAN'
                    className="mt-2 text-white"
                    variant="label"
                    />
                    </div>
                  }
                {currentPlan.WELCOME_PACKAGE &&
                 <div className={planButtonStyle}>

                    <Typography
                    translationKey='PROMOTIONS.CURRENT_FREE_PLAN'
                    className="mt-2 text-white"
                    variant="label"
                    />
                    </div>
                  }
            </div>
              )
            })}

      </div>
      </div>
    </div>
  )
}