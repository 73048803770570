import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useUpdateVendorDetails } from "../query"
import { Typography, ListinDetailsTemplate, Button } from "../../../component";
import { Alert } from "../../../component/alert";
import { PreviewToggle } from "./preview-toggle.component";
import { ROUTE_PATHS } from "../../../constants";
import { ListingForm } from "../../add-listing/form";
import { ProfileLogo } from "./profile-logo.component";
import { useVendorContext } from "../context";
import { getWelcomePackageInfo } from "../../../utils";
import { getIsVendorRecommended } from "../../admin/vendors/utils/utils";

export const VendorProfile = () => { 
  const [isPreviewing, setIsPreviewing] = useState(false)
  const { vendor } = useVendorContext()
  const navigate = useNavigate()
  const { mutate, isLoading: isloadingMutation, invalidateCache } = useUpdateVendorDetails()
  const isRecommended = useMemo(() => getIsVendorRecommended(vendor?.promotions), [vendor?.promotions])
  const { isWelcomePackageActive } = getWelcomePackageInfo(vendor?.verifiedAt, isRecommended)

  const openPromotions = () => {
    navigate('/' + ROUTE_PATHS.VENDOR.PROMOTE)
  }

  return (
    <div className="relative">      
      
      <div className="flex md:flex-row flex-col justify-between items-center mt-10 ">
        <Typography
          translationKey="VENDOR.WELCOME"
          values={{ vendorName: vendor.name }}
          variant='h1'
          className="md:text-2xl text-center md:text-left text-xl"
        /> 
        <ProfileLogo vendor={vendor}/>
      </div>
      {isWelcomePackageActive &&
       <Alert message="VENDOR.ADVERT.WELCOME_PACKAGE_ON" action={<Button variant="ghost" onClick={openPromotions} className="inline border-0 text-gold-300 underline" translationKey="VENDOR.ADVERT.GET_STARTED_HERE" />} iconSize={16} type='info' className='my-6'/>
      }
      {isRecommended &&
       <Alert type='success' message="VENDOR.ADVERT.RECOMMENDED_PACKAGE" action={<Button variant="ghost" onClick={openPromotions} className="inline border-0 text-gold-300 underline" translationKey="VENDOR.ADVERT.GET_STARTED_HERE" />} iconSize={16} className='my-6'/>
      }

      {!isWelcomePackageActive && !isRecommended &&
       <Alert message="VENDOR.ADVERT.MESSAGE" action={<Button variant="ghost" onClick={openPromotions} className="inline border-0 text-gold-300 underline" translationKey="VENDOR.ADVERT.GET_STARTED_HERE" />} iconSize={16} type='info' className='my-6'/>
      }
      
      <div className="my-4"><PreviewToggle setIsPreviewing={setIsPreviewing} isPreviewing={isPreviewing}/></div>
      {isPreviewing ? <div className="w-full h-full p-6">
          <ListinDetailsTemplate vendor={vendor} isPreviewing />
      </div>
      :
      <ListingForm
        className='px-0'
        mutationLoading={isloadingMutation}
        mutateFn={mutate} 
        onSuccess={() => invalidateCache(vendor.id)}
        defaultValues={{...vendor, businessName: vendor?.name, socialMedia: JSON.parse(vendor?.socialMedia )}} 
        isEditing 
      />
      }    
    </div>
  )
}