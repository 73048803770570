import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiRoutes, axiosInstance } from '../../../api';


export const useGetAuthedVendorDetails = (id, params) => {
    
  return useQuery({
    queryKey: ['auth-vendor-details', id],
    queryFn: async () => {
      const { data } = await axiosInstance.get(`${apiRoutes.authVendors}/${id}`, { params })
      
      return data;
    },
  },
  {
    enabled: !!id
  })
}

export const useUpdateVendorDetails = () => {
  const query = useQueryClient()
  
  const mutation = useMutation(async ({ id, values }) => {
      const { data } = await axiosInstance.put(`${apiRoutes.vendors}/${id}`, {...values})

      return data
    })

    const invalidateCache = (vendorId) => {
      return query.invalidateQueries({queryKey: ['auth-vendor-details', vendorId]})
    }

    return { ...mutation, invalidateCache }
}

export const useUpdateVendorLogo = () => {
  const query = useQueryClient()
  
  const mutation = useMutation(async ({ id, logo }) => {
      const { data } = await axiosInstance.put(`${apiRoutes.vendors}/${id}/logo`, { logo })

      return data
    })

    const invalidateCache = (vendorId) => {
      return query.invalidateQueries({queryKey: ['auth-vendor-details', vendorId]})
    }

    return { ...mutation, invalidateCache }
}