import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {  Lock, Mail } from "react-feather";
import { InputField } from "../../component/form"
import { useLoginSchema } from "./schema"
import { Button, Typography, AnimationWrapper } from "../../component";

import { useLogin } from "../../common/query";
import { toastUtil } from "../../utils";
import { Loader } from "../../component/loader";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants";
import { useAuth } from "../../core";

export const Login = () => {
  const { schema } = useLoginSchema()
  const navigate = useNavigate();
  const { mutate, isLoading } = useLogin();
  const auth = useAuth()
  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {

    mutate({...values, isVendorUser: true }, {
      onError: (err => toastUtil({message: err.response?.data?.message, theme: 'failure'})),
      onSuccess: (response) => {
        auth?.setUpdatedAuthStatus({authenticated: true, id: response.id, isAdmin: response.isAdmin })
        const route = response.isAdmin ? ROUTE_PATHS.ADMIN.DASHBOARD : ROUTE_PATHS.VENDOR.DASHBOARD
        navigate('/' + route)
      }
    })

  }
  return <AnimationWrapper>

      <div className="h-full bg-gray-100 py-6">

       <div className="flex justify-center">
        <Typography
              translationKey="LOGIN.VENDOR_LOGIN"
              variant="h2"
            />
       </div>
      <div className="md:w-4/12 w-11/12 mt-8 mb-5 py-4 rounded-lg border border-neutral-100 shadow bg-white h-auto mx-auto">
        <form className="relative h-full p-4" onSubmit={handleSubmit(onSubmit)}>

          <InputField
          register={{...register('email')}}
          label="ADD_LISTING.FIELD_LABELS.EMAIL"
          className="py-4"
          inputClassName='bg-gray-50'
          error={errors?.email?.message}
          startIcon={<Mail className="text-gray-300" />}
          required
        />
          <InputField
          register={{...register('password')}}
          label="ADD_LISTING.FIELD_LABELS.PASSWORD"
          className="py-4"
          inputClassName='bg-gray-50'
          error={errors?.password?.message}
          startIcon={<Lock className="text-gray-300" />}
          type="password"
          required
        />

        {isLoading && (
          <div className="flex justify-center">
            <Loader size="30"/>
          </div>
        )}

        <Button
          translationKey="LOGIN.LABEL"
          className='w-full mt-4 mb-4'
          variant='primary'
          size='lg'
          type='submit'
          disabled={isLoading}
        />
      </form>
      <div className="flex md:flex-row flex-col flex-wrap items-center justify-center md:justify-between mt-8 md:mt-0 px-4 py-4">
        <div className="flex items-center justify-center flex-nowrap">
          <Typography
            className="mr-2 whitespace-nowrap text-sm"
            translationKey="LOGIN.NO_ACCOUNT"
            variant="label"
          />
          <Button
            translationKey="LOGIN.CREATE_ONE"
            variant="link"
            className="whitespace-nowrap text-sm"
            onClick={() => navigate("/" + ROUTE_PATHS.ADD_LISTING)}
          />
        </div>

        <Button
            translationKey="LOGIN.FORGOT_PASSWORD"
            variant="link"
            onClick={() => navigate("/" + ROUTE_PATHS.FORGOT_PASSWORD)}
            className="text-black text-sm underline whitespace-nowrap mt-3 md:mt-0"
          />
      </div>
      </div>
    </div>
  </AnimationWrapper>
}