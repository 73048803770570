import { Typography } from '../../component'

import vendor from '../../assets/images/vendor.jpg'

export const VendorMarketing = () => {

  return (
    <div className="w-full lex flex-col h-full py-10">
       
      <div className="container my-4 md:my-8 flex md:flex-row flex-col md:justify-center mx-auto">
        <div className="max-w-[500px] px-2 flex flex-col md:mr-16">
          <Typography
            translationKey="VENDOR_MARKETING.LABEL"
            variant="h1"
            className="md:max-w-[500px] md:text-left text-center text-2xl md:my-4 md:text-5xl mb-4 md:mb-2 font-black"
          />

         <img src={vendor} alt="About Tradeskonnect" className="md:hidden rounded-lg max-h-[150px] px-6 lg:px-0 mb-6 xl:mb-0 xl:max-h-[350px]"/>

          <Typography
            translationKey="VENDOR_MARKETING.DESCRIPTION"
            variant="label"
            className="mb-4 md:mb-8 text-md md:px-0 px-4 md:text-left text-center"
          />
        </div>
        <img src={vendor} alt="About Tradeskonnect" className="hidden md:block rounded-lg max-h-[150px] px-6 lg:px-0 mb-6 xl:mb-0 xl:max-h-[350px]"/>
      </div>
    </div>
  )
}