import React from 'react';
import "react-multi-carousel/lib/styles.css";
import { useParams } from 'react-router-dom';
import { useGetVendorDetails } from '../../common/query';
import { Error404Page, Loader, ListinDetailsTemplate, AnimationWrapper } from '../../component';


export const ListinDetails = () => {

  const { vendorSlug } = useParams()
  const { data: vendorDetails, isLoading, isError } = useGetVendorDetails(vendorSlug, { include:'reviews' })

  if (isLoading) {
    return <div className='flex justify-center items-center h-full'>
        <Loader/>
    </div>
  }

  const { vendor } = vendorDetails ?? {};

  if (!isLoading && (isError || !vendor)) {
    return <Error404Page />
  }


  return (
    <AnimationWrapper>
        <ListinDetailsTemplate vendor={vendor}/>
    </AnimationWrapper>
  )
}