import React from 'react';
import classNames from "classnames"
import PlacesAutocomplete, {
  geocodeByAddress,
}from 'react-places-autocomplete';
import { useIntl } from 'react-intl';
import { getAddressParts } from '../../utils';

export const AddressAutoComplete = ({ required, error, address, setAddress, setAddressParts, labelClassName, inputClassName, label }) => {
  const { formatMessage } = useIntl()
  const labelTranslation = formatMessage({ id: label })
  const addressError = formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.ADDRESS_REQUIRED' })

  const handleSelect = async address => {
    const geoCode = await geocodeByAddress(address)

    setAddressParts(getAddressParts(geoCode))
    setAddress(address)

  };

  const searchOptions = {
    componentRestrictions: { country: ['ca'] }
  }

    return (
      <PlacesAutocomplete
        value={address}
        onChange={(add) => setAddress(add)}
        onSelect={handleSelect}
        searchOptions={searchOptions}
        >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            {required && <span className="text-red-300 text-md mr-1">*</span>}
            <label className={labelClassName}>
             {labelTranslation}
            </label>
            <input
              {...getInputProps({
                placeholder: 'Type to search...',
                className: classNames('h-12 w-full mt-2 px-2 border rounded-md outline-none focus:border-2', inputClassName, 'location-search-input')
              })}
            />
           { error && <span className="text-red-300 mt-1">{addressError}</span>}

            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    key={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion, {
                      className: classNames(className, 'p-2 bg-red-300'),
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
