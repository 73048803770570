import { Copy, Facebook, Instagram, Mail, PhoneCall, Twitter } from "react-feather"
import { Typography } from ".."
import { toastUtil } from "../../utils"


export const ContactDetails = ({ vendor }) => {
  const socialMedia = vendor?.socialMedia && JSON.parse(vendor?.socialMedia)
  
  const copyText = (value, field) => {
    navigator.clipboard.writeText(value);
    toastUtil({ message: `${field} copied`, theme: 'success', duration: 2000 })
  }
  const hasSocialMediaHandle = socialMedia?.facebook || socialMedia?.twitter || socialMedia?.instagram
  return (
    <div className="flex flex-col px-4 py-3">
          <div className="flex flex-col w-full">
            <Typography
              translationKey="ADD_LISTING.FIELD_LABELS.EMAIL"
            />
            <div className="flex items-center justify-between mt-2">
              <span>{vendor.email}</span>
              <div className="flex items-center">
              <a href={`mailto:${vendor.email}`} target="_blank" rel="noreferrer" >
                <Mail size={20} className="mr-5 cursor-pointer hover:text-blue-300"/>
              </a>
                <Copy size={20} className="cursor-pointer hover:text-blue-300" onClick={() => copyText(vendor.email, 'Email')}/>
              </div>
            </div>

          </div>
          <div className="border border-gray-200 h-[1px] mt-4"/>

          <div className="flex flex-col w-full mt-3">
          <Typography
              translationKey="ADD_LISTING.FIELD_LABELS.PHONE_NUMBER"
            />
            <div className="flex items-center justify-between mt-2">
              <span>{vendor.contactNumber}</span>
              <div className="flex items-center">
              <a href={`tel:${vendor.contactNumber}`} target="_blank" rel="noreferrer" >
                <PhoneCall size={20} className="mr-5 cursor-pointer hover:text-blue-600"/>
              </a>
                <Copy size={20} className="cursor-pointer hover:text-blue-300" onClick={() => copyText(vendor.contactNumber, 'Phone number')}/>
              </div>
            </div>

          </div>
          {hasSocialMediaHandle && <>
            <div className="border border-gray-200 h-[1px]"/>
            <div className="flex mt-4">
              <div className="flex flex-col">
              <Typography
                translationKey="VENDOR.CONTACT_FORM.SOCIAL_MEDIA"
              />
              <div className="flex flex-row mt-4 justify-between">
                { socialMedia?.facebook && <a href={`https://www.facebook.com/${socialMedia.facebook}`} target="_blank" rel="noreferrer" className="cursor-pointer">
                    <Facebook size={25} className="text-gray-500"/>
                    </a>
                  }
                  {socialMedia?.instagram && <a href={`https://www.instagram.com/${socialMedia.instagram}`} target="_blank" rel="noreferrer" className="cursor-pointer">
                      <Instagram size={25} className="text-gray-500"/>
                  </a>}
                { socialMedia?.twitter && <a href={`https://www.twitter.com/${socialMedia.twitter}`} target="_blank" rel="noreferrer" className="cursor-pointer">
                  <Twitter size={25} className="text-gray-500"/>
                  </a>
                  }
              </div>
              </div>

            </div>
          
          </>}
        </div>
  ) 
}