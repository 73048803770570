import { Outlet } from "react-router-dom";
import { useAuth } from "../../core";
import { MenuLinks } from "../Header";
import { AnimationWrapper } from "../../component";
import { AdminRoutes, vendorRoutes } from "../../utils";

export const RouteWrapper = () => {
  const auth = useAuth();
  return (
    <AnimationWrapper>
        <div className="flex mx-auto flex-1">
          <div className="h-screen w-[200px] md:flex hidden bg-gray-100 drop-shadow">
            <ul className="flex mt-8 justify-start flex-col w-full lg:mx-2 px-2">
              <MenuLinks setShowSideMenu={() => {}} menuLinks={auth?.isAdmin ? AdminRoutes : vendorRoutes } />
            </ul>
          </div>
          <div 
            animate={{ x: 100 }}
            transition={{ delay: 1 }}
            className="md:px-8 md:h-screen md:pb-[50px] md:overflow-auto px-4 w-full">
            <Outlet />
        </div>
      </div>
    </AnimationWrapper>
  )
}