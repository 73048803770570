import { X } from "react-feather";
import classNames from "classnames";

import { MenuLinks } from "./menu-link.component";
import { Button } from "../../component";
import { TradesKonnectLogo } from "./trades-konnect.component";

export const MobileSlide = ({showlogButton = false,  handleLogout, showSideMenu, setShowSideMenu, menuLinks, showAddListingBtn }) => {
  return (
      <div
        className={classNames(
          "lg:hidden w-[70%] pt-4 h-screen fixed top-0 z-[100] overflow-y:auto bg-white transition-left duration-700 ease",
          { "left-0": showSideMenu, "left-[-80%]": !showSideMenu }
        )}
      >
        <TradesKonnectLogo className="text-sm" imageClassName="h-[25px] ml-4"/>
        <button
          onClick={() => setShowSideMenu(!showSideMenu)}
          className="outline-none mr-2 bg-red-300 h-[30px] w-[30px] ml-auto rounded-[50%] flex items-center justify-center text-white"
        >
          <X size={16} className="" />
        </button>
        <div className="flex flex-col h-full mt-6">
          <ul>
            <MenuLinks setShowSideMenu={setShowSideMenu} menuLinks={menuLinks} />
          </ul>
          {showlogButton && <Button
            onClick={handleLogout}
            translationKey="LOGOUT"
            variant="destructivePrimary"
            className="py-2 mt-6 mx-3.5"
          />}
        </div>

      </div>
  );
};
