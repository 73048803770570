import { useIntl } from "react-intl";
import * as yup from "yup";
import "yup-phone-lite";

export const useReviewVendorSchema = () => {

  const intl = useIntl()

  return yup.object({
    starCount: yup.number().required(
      intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.STAR_COUNT_ERROR'})
      ),
      reviewComment: yup.string()
  }).required();
}