import { useQueryClient } from "react-query";
import { Grid, Loader } from "../../../component";
import { toastUtil } from "../../../utils";
import { Header } from "../header"
import { ACTION_TYPES, COLUMNS } from "./constant";
import { useAdminUpdatePromotionMutation, useGetPromotions } from "./query";

export const Promotions = () => {

  const { data, isLoading } = useGetPromotions();
  const { mutate, isLoading: isMutatingPromotion } = useAdminUpdatePromotionMutation()
  const queryClient = useQueryClient()
  if (isLoading) {
    return <div className="w-full flex justify-center items-center"><Loader/></div>
  }

  const handleSave = (selectedValue) => {
    mutate({ promotionId: selectedValue.id, isActive: Boolean(selectedValue.value)}, {
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: ['promotions']})
        toastUtil({ message: 'Promotion Updated', position: 'top-center', theme: 'success' })
      },
      onError: (error) => toastUtil({ message: error.response.data.message, position: 'top-center', theme: 'failure' })
    })
  }

    return <div className="container mx-auto mt-12">
      <Header text="ADMIN.PROMOTIONS.LABEL"/>
      <Grid
        className="mt-3"
        columns={COLUMNS}
        contentData={data.promotions}
        isLoading={isMutatingPromotion}
        getActionTypes={() => ACTION_TYPES}
        onSave={handleSave}
      />
    </div>
}