import {
  MapPin,
  Book,
  XCircle
} from "react-feather";
import Select from 'react-select';
import { useGetCities, useServices } from "../../common/query";
import classNames from "classnames";
import { useMemo } from "react";


const SearchButton = ({ disableSearchButton, refetch }) => {
  return <button disabled={disableSearchButton} onClick={refetch} className={classNames("text-white md:h-auto h-14 mt-4 md:mt-0 w-full md:w-auto transition duration-300 ease rounded px-6 font-semibold", {
    'bg-red-700 cursor-pointer': !disableSearchButton,
    'cursor-not-allowed bg-gray-400': disableSearchButton
  })}>
    Search Vendors
  </button>
}

export const Filter = ({
  getFilterValue,
  refetch,
  reset,
  filters
}) => {

  const {data: services, isLoadingServices } = useServices();
  const {data: cities, isLoading: isLoadingCities } = useGetCities();
  const isLoading = isLoadingCities || isLoadingServices
  const disableSearchButton = !filters?.category || !filters?.location?.length

  const style = (isMobile = false) => ({
    control: base => ({
      ...base,
      border: 0,
      minHeight: isMobile ? '30px' : '48px',
      backgroundColor: 'transparent',
      boxShadow: "none",
      width: '100%',

    })
  });

  const selectedLocation = useMemo(() => cities?.cities.filter(cit => cit.value === filters?.location), [filters?.location, cities?.cities])
  
  return (
    <>
    <div className="md:flex hidden bg-white rounded h-16 w-full lg:w-[1000px] flex py-2 pl-4 pr-2 drop-shadow-2xl">
      <div className="hidden md:flex items-center flex-1 pr-4">
        <Book className="text-red-700"/>
        <select disabled={isLoading} className="ml-1 w-full outline-none h-full" onChange={(e) => getFilterValue({...filters, category: e.target.value})}>
          <option disabled selected={!filters?.category}>Choose Category</option>
          {services?.services?.map(service =>  <option selected={filters?.category === service.label} key={service.value}>{service.label}</option>)}
        </select>
       { filters?.category && <XCircle scale={5} onClick={reset}/>}
      </div>


      <div className="items-center flex-1 pr-4 hidden md:flex">
        <MapPin className="text-red-700"/>
        <Select
          options={cities?.cities}
          placeholder="Location, e.g Milton"
          isMulti={false}
          styles={style()}
          value={selectedLocation}
          onChange={(e) => getFilterValue({...filters, location: e.value})}
          isDisabled={!filters?.category}
          menuPortalTarget={document.body}
          className={classNames('px-2 border w-full rounded-md outline-none focus:border-2 transition duration-300 rounded-md ease', {
            'bg-gray-200': !filters?.category,
            'bg-white': filters?.category,
          })}
        />
      </div>
      <button disabled={disableSearchButton} onClick={refetch} className={classNames("text-white transition duration-300 ease rounded px-6 font-semibold", {
        'bg-red-700 cursor-pointer': !disableSearchButton,
        'cursor-not-allowed bg-gray-400': disableSearchButton
      })}>
        Search Vendors
      </button>
    </div>

    <div className="lg:w-[1000px] md:hidden flex-col drop-shadow-2xl">
      <div className="mt-4 rounded h-10 py-2 pl-4 pr-2 w-full bg-white flex items-center flex-1 pr-4">
        <Book className="text-red-700"/>
        <select disabled={isLoading} className="ml-1 w-full outline-none h-full" onChange={(e) => getFilterValue({...filters, category: e.target.value})}>
          <option disabled selected={!filters?.category}>Choose Categrory</option>
          {services?.services?.map(service =>  <option key={service.value}>{service.label}</option>)}
        </select>
        { filters?.category && <XCircle scale={5} onClick={reset}/>}
      </div>

      <div className={classNames("mt-4 rounded h-10 py-2 pl-4 pr-2 w-full flex items-center flex-1 pr-4 transition duration-300 ease", 
      {
        'bg-gray-200': !filters?.category,
        'bg-white': filters?.category,
      })}>
        <MapPin className="text-red-700"/>
        <Select
          options={cities?.cities}
          placeholder="Location, e.g Milton"
          isMulti={false}
          styles={style(true)}
          value={selectedLocation}
          onChange={(e) => getFilterValue({...filters, location: e.value})}
          isDisabled={!filters?.category}
          menuPortalTarget={document.body}
          className={classNames('px-2 border-0 w-full rounded-md outline-none focus:border-2 rounded-md ease', {
            'bg-gray-200': !filters?.category,
            'bg-white': filters?.category,
          })}
        />
      </div>
      <SearchButton disableSearchButton={disableSearchButton} refetch={refetch}/>
    </div>
    
    </>
  )
}