import React, { useEffect } from 'react';
import "react-multi-carousel/lib/styles.css";
import { Description } from './description';
import { ContactForm } from './contact-vendor';
import { Gallery, Card, Ratings } from '../../component';
import classNames from 'classnames';
import { updateSeoTags } from '../../utils/seo';

export const ListinDetailsTemplate = ({ vendor, isPreviewing = false }) => {

  useEffect(() => {

    if (vendor) {
      updateSeoTags({
        title: vendor.name + ' - ' + vendor.description.substring(0, 40) + '...',
        metaDescription: vendor.description
      })
    }
  }, [vendor])

  return (
    <div className='bg-gray-200 pb-16 md:px-0 h-full'>
    <div className="h-[220px] bg-home-background bg-center md:bg-right">
      <div className="h-full bg-opacity-60 bg-blue-500 flex flex-col justify-center items-center bg-center">
      <div className="pt-6 px-2 md:px-6 container mx-auto relative rounded-t-lg">
        <div className={classNames('mb-4 mt-[150px] px-2 md:top-[20px]', { 'mt-[50px]': isPreviewing})}>
          <Card
            vendor={vendor} 
            className='md:h-[150px] md:max-w-[450px] rounded-xl p-1 pointer-events-none' 
            imageContainerClassName='mr-4 flex items-center justify-center'
            imageClassName='md:h-full p-0'
            showAllServices
            />
        </div>
      </div>
        </div>
    </div>

    <div className="md:container px-4 mt-[130px] md:mt-[150px] mx-auto md:px-8">
      <div className='flex md:flex-row flex-col md:justify-between w-full'>
        
        <div className='md:w-4/5'>
          <div className='h-max bg-white rounded-lg'>
            <Description description={vendor.description}/>
          </div>
           <Gallery isPreviewing={isPreviewing} canUpload={false} vendorImages={vendor?.images}/>
        </div>

        <div className='hidden md:block w-20'/>
        <div className='md:w-2/5 w-full h-max mt-4 md:mt-0'>
          <div className='bg-white rounded-lg'>
            <Ratings vendorReviews={vendor.vendorReviews} averageRating={vendor.averageRating}/>
          </div>
          <ContactForm vendor={vendor} isPreviewing={isPreviewing}/>
        </div>
      </div>
    </div>
    </div>
  )
}