import { User, UserPlus, Volume2 } from "react-feather"
import { ROUTE_LABELS, ROUTE_PATHS } from "../constants"
import moment from "moment"
import config from '../config'

export const getAddressParts = (address) => {

  const province = address[0].address_components.find(com => com.types.includes('administrative_area_level_1'))
    const city = address[0].address_components.find(com => com.types.includes('locality') || com.types.includes('sublocality_level_1') || com.types.includes('"sublocality"'))
    
    return { city: city?.long_name, province: province?.long_name}

}

export const isFileTypeSupported = (file) => {

  if (file) {
    const ext = file?.type?.split('/')[1]
    return ['png', 'jpeg', 'jpg'].includes(ext);
  }

  return false;
}

export const openInputFile = (onChange) => {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'accept="image/png, image/jpeg, image/jpg"')
  input.click()

  input.onchange = (event) => {
    onChange(event.target.files[0])
  }
}

export const mapVendorUpdateDetails = (values, addressParts) => {
  return {
    addressParts: addressParts ? JSON.stringify(addressParts) : JSON.parse(values?.addressParts),
    socialMedia: JSON.stringify(values.socialMedia),
    email: values.email,
    address: values.address,
    description: values.description,
    services: values.services.map(service => service.label),
    contactNumber: values.contactNumber,
    businessName: values.businessName,
  }
}


export const vendorRoutes = [
  { to: ROUTE_PATHS.VENDOR.DASHBOARD, label: ROUTE_LABELS.VENDOR.DASHBOARD, icon: <User size={14}/> },
  { to: ROUTE_PATHS.VENDOR.GALLERY, label: ROUTE_LABELS.VENDOR.GALLERY, icon: <Volume2 size={14}/> },
  { to: ROUTE_PATHS.VENDOR.REVIEWS, label: ROUTE_LABELS.VENDOR.REVIEWS, icon: <Volume2 size={14}/> },
  { to: ROUTE_PATHS.VENDOR.PROMOTE, label: ROUTE_LABELS.VENDOR.PROMOTE, icon: <Volume2 size={14}/> }
];

export const AdminRoutes = [
  { to: ROUTE_PATHS.ADMIN.DASHBOARD, label: ROUTE_LABELS.ADMIN.DASHBOARD, icon: <User size={14}/> },
  { to: ROUTE_PATHS.ADMIN.PROMOTIONS, label: ROUTE_LABELS.ADMIN.PROMOTIONS, icon: <Volume2 size={14}/> },
  { to: ROUTE_PATHS.ADMIN.VENDORS, label: ROUTE_LABELS.ADMIN.VENDORS, icon: <UserPlus size={14}/> },
  { to: ROUTE_PATHS.ADMIN.USERS, label: ROUTE_LABELS.ADMIN.USERS, icon: <UserPlus size={14}/> },
];

export const PROMOTION_TYPES = {
  recommended: 'Recommended',
  instagramMarketing: 'InstagramMarketing',
  vom: "VOM",
  topVendor: 'tov'
}

export const USER_ACTION_TYPES = {
  sendReviewMail: 'sendMail'
}

export const sortData = (records = [], sortField, direction) => {
  let dir = direction;
  dir = dir === 'asc' ? 'desc' : 'asc';

  const newData = records.sort((a,b) => {

    if (a[sortField] < b[sortField] && dir === 'asc') {
      return -1
    } 
    
    if (a[sortField] > b[sortField] && dir === 'desc') {
      return -1

    } else {

      return 1
    }
  })

  return { sortedData: newData, dir }
}

export const getWelcomePackageInfo = (verifiedAt, isRecommended) => {

  if (isRecommended) return false;

  const endFreeDate =  moment(verifiedAt).add('days', 30).format('YYYY-MM-DD')
  const isWelcomePackageActive = moment(endFreeDate).isAfter(new Date())
  
  return { endFreeDate, isWelcomePackageActive }
}

export const getDisplayAddress = (address) => {

  if (!address.city && !address.province) {
    return '';
  }

  if (address.city && !address.province) {
   return address.city
  }

  if (!address.city && address.province) {
    return address.province 
  }


  return `${address.city}, ${address.province}`;
}

const baseApi = 'api/v1'

export const getEnvApi = (env) => {

  if (env === 'development') {
    return `http://127.0.0.1:3003/${baseApi}`
  }

  return config.isProd ? `https://api.tradeskonnect.com/${baseApi}` : `https://dev.tradeskonnect.com/${baseApi}`
}