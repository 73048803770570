import { Link } from "react-router-dom";
import { Button, Typography } from "../../component";
import { ROUTE_LABELS, ROUTE_PATHS } from "../../constants";
import { FeedbackForm } from "../../component/feedback-form";

export const Footer = () => {
  const linkTexts = [
    { label: ROUTE_LABELS.PRIVACY, path: ROUTE_PATHS.PRIVACY },
    { label: ROUTE_LABELS.TERMS, path: ROUTE_PATHS.TERMS },
  ]
  const siteMaps = [
    { label: ROUTE_LABELS.HOME, path: ROUTE_PATHS.HOME },
    { label: ROUTE_LABELS.VENDORS, path: ROUTE_PATHS.LISTINGS },
    { label: ROUTE_LABELS.ABOUT, path: ROUTE_PATHS.ABOUT },
    { label: ROUTE_LABELS.CONTACT, path: ROUTE_PATHS.CONTACT },
  ]
  return (
    <div className="w-full px-2 lg:px-0 bg-gray-600">
      <div className="container flex-col md:flex-row items-center md:items-start w-full md:w-3/5 flex-wrap flex justify-around mx-auto px-8 py-8 md:py-8">
      <div className="flex flex-col md:items-start items-center my-3 md:my-0">
        <Typography
            translationKey="FOOTER.SITE_MAP"
            variant="h2"
            className="text-white"
          />
        {
          siteMaps.map(({ label, path}) => (
            <Link key={label} to={`/${path}`}>
              <p className="text-gray-300 transition duration-1000 ease border-1 my-2 hover:text-white hover:underline focus:border-1">{label}</p>
            </Link>
          ))
        }
      </div>
      <div className="flex flex-col md:items-start items-center my-3 md:my-0">
        <Typography
            translationKey="FOOTER.INFORMATION"
            variant="h2"
            className="text-white font-bold"
          />
        {
          linkTexts.map(({ label, path}) => (
            <Link key={label} to={`/${path}`}>
              <Button
                translationKey={`FOOTER.${label.toUpperCase()}`}
                variant="link"
                className="text-gray-300 transition duration-1000 ease border-1 my-2 hover:text-white focus:border-1"
              />
            </Link>
          ))
        }
      </div>
      <FeedbackForm />
      </div>
      <div className="flex mx-auto mb-4 justify-center">
        <Typography
          translationKey="FOOTER.ADDRESS"
          variant="label"
          className="text-white text-center text-xs"
        />
      </div>
    </div>
  );
};
