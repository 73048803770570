
import React from 'react';
import { useResendInviteLink } from './query';
import { Loader } from '../../component/loader';
import { Button } from '../../component';
import { InputField } from '../../component/form';
import { useResendInviteSchema } from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { toastUtil } from '../../utils';
import { useNavigate } from 'react-router-dom';

export const ResendInviteLinkForm = () => {
  const { schema } = useResendInviteSchema()
  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate()
  const { mutate, isLoading } = useResendInviteLink();

const onSubmit = (values) => {
  mutate(values.email, {
    onError: (error) => toastUtil({message: error.response.data.message, theme: 'failure', duration: 5000}),
    onSuccess: () => { 
      toastUtil({message: `Link has been sent to ${values.email}`, theme: 'success', duration: 5000})
    setTimeout(() => navigate('/login'), 1000)
    }
  })
}

  if (isLoading) {
    return <div className='flex justify-center items-center h-full'>
        <Loader size='30'/>
    </div>
  }

  return (
    <form className='flex flex-col mx-auto justify-center items-center w-full mt-16' onSubmit={handleSubmit(onSubmit)}>
      <InputField
        register={{...register('email')}}
        label="ADD_LISTING.FIELD_LABELS.EMAIL"
        className="py-4 w-[300px]"
        inputClassName='bg-gray-50'
        placeholder='Your email'
        error={errors?.email?.message}
        required
      />
        <Button
          translationKey="ACCOUNT_VERIFICATION.SEND_LINK"
          className='w-[300px] mt-2 mx-auto mt-4'
          size='lg'
          variant='primary'
          type='submit'
          disabled={isLoading}
        />
    </form>
      )
}