import { useQuery } from 'react-query';
import {apiRoutes, axiosInstance } from '../../api';
import { useEffect } from 'react';
import { PAGINATION_ITEMS_PER_PAGE } from '../../constants';

export const useGetListings = (params, shouldReset = false) => {
  const { refetch, data, isLoading } = useQuery(['listings'], async () => {
    const { data } = await axiosInstance.get(apiRoutes.vendors, { params });

    return data;
  });

  useEffect(() => {
    // Feels like a hack
    if(shouldReset) {
      refetch({
        page: 1,
        pageSize: PAGINATION_ITEMS_PER_PAGE,
        include:'reviews',
      })
    } else {
      refetch()
    }
  }, [
    params.page,
    refetch,
    shouldReset
  ])

  return { data, refetch, isLoading }
}

export const useGetTopVendors = () => {

  return useQuery(['top-listings'], async () => {
    const { data } = await axiosInstance.get(apiRoutes.topVendors);

    return data;
  });
}
