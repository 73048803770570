import { useMutation, useQueryClient } from "react-query";
import { apiRoutes, axiosInstance } from "../../../api";
import { toastUtil } from "../../../utils";

export const useAdminResetUserOrVendorMutation = () => {

	const queryClient = useQueryClient()
		return useMutation(async (details) => {
			const { data } = await axiosInstance.patch(apiRoutes.admin.resetUserOrVendor, {...details})
				return data;
			},
			{
				onSuccess: (response) => {
					queryClient.invalidateQueries({queryKey: [response.type === 'vendor' ? 'listings' : 'users']})
					toastUtil({ message: response.message, theme: 'success'})
				},
				onError: (error) => {
					toastUtil({ message: error.response.data.message, theme: 'failure'})
				}
			}
		)
}