import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputField } from "../../component/form"
import { AnimationWrapper, Button, Typography } from "../../component";

import { toastUtil } from "../../utils";
import { useResetPasswordSchema } from "./schema";
import { useResetPasswordMutation } from "./query";
import { useParams } from "react-router-dom";

export const ResetPassword = () => {
  const { uniqueLink } = useParams()
  const { mutate, isLoading, isSuccess, isError } = useResetPasswordMutation();
	const { schema } = useResetPasswordSchema()

	const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    mutate({ password: values.password, uniqueLink }, {
      onError: (err => {
				toastUtil({message: err.response?.data?.message, theme: 'failure'})
			}),
      onSuccess: (response) => toastUtil({message: response?.message, theme: 'success'})
    })

  }
  return <AnimationWrapper>

  <div className="h-full bg-gray-100 py-6">
       <div className="flex justify-center">
        <Typography
              translationKey="RESET_PASSWORD.LABEL"
              variant="h2"
          />
       </div>
      <div className="md:w-4/12 w-11/12 mt-8 mb-5 pb-4 rounded-lg border border-neutral-100 shadow bg-white h-auto mx-auto">
        <form className="relative h-full p-4" onSubmit={handleSubmit(onSubmit)}>
        <InputField
          register={{...register('password')}}
          label="ADD_LISTING.FIELD_LABELS.PASSWORD"
          className="py-4"
          inputClassName='bg-gray-50'
          error={errors?.password?.message}
          required
          type='password'
        />
        <InputField
          register={{...register('confirmPassword')}}
          label="ADD_LISTING.FIELD_LABELS.CONFIRM_PASS"
          className="py-4"
          inputClassName='bg-gray-50'
          error={errors?.confirmPassword?.message}
          required
          type='password'
        />
        <Button
          translationKey="RESET_PASSWORD.SAVE_PASSWORD"
          className='w-full md:w-auto ml-auto mt-4 mb-4'
          variant='primary'
          size='lg'
          type='submit'
          disabled={isLoading || isSuccess || isError}
					isLoading={isLoading}
        />
      </form>
      </div>
  </div>
  </AnimationWrapper>

}