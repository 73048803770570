import classNames from "classnames"
import { Button } from "../button"
import Select from 'react-select';
import { sortData } from "../../utils";
import { useEffect, useState } from "react";
import moment from 'moment'
import { Typography } from "../typography";

const dateFields = ['createdAt', 'startDate', 'endDate', 'updatedAt', 'lastReviewRequest']
 export const Grid = ({getActionTypes, showActions = true, isLoading, onSave, className, columns = [], contentData = [] }) => {

	const [records, setRecords] = useState([])

	useEffect(() => {
		setRecords(contentData)
	}, [contentData])

	const [selectedAction, setSelectedAction] = useState({ id: '', value: ''});
	const [sortDirection, setSortDirection] = useState('asc')
	
	const getColumnValue = (value, column = '') => {
		if (typeof value === 'boolean')
		return value ? 'Yes' : 'No'

		if (dateFields.includes(column) && value !== null) {
			return column === 'updatedAt' ? moment(value).fromNow() : moment(value).format("MMM Do, YYYY");  
		}

		return value === null ? '-' : value 
	}

	const handleDataSort = (sortField) => {

		const { sortedData, dir } = sortData(records, sortField, sortDirection)

			setSortDirection(dir);
			setRecords(sortedData)
	}

	if (!contentData.length) {
		return <div className="mt-16 flex justify-center">
				<Typography
						translationKey="GRID.EMPTY_MESSAGE"
						variant="heading2"
					/>
		</div>
	}

	return <table className={classNames("table-auto w-full", className)}>
		<thead>
			<tr className="bg-gray-200 rounded-lg h-10">
				{columns.map(column => <th className="font-bold px-2 border border-b border-white border-b-black cursor-pointer" key={column.accessor} onClick={() => handleDataSort(column.accessor)}>{column?.name}</th>)}
				{showActions && <th className="font-bold px-2 border border-b border-white border-b-black">Actions</th>}
			</tr>
		</thead>

		<tbody>
			{records.map(data => {
				return (
					<tr className="w-full hover:bg-gray-100" key={data.id}>
						{columns.map(column => <td key={column.accessor} className="px-2 py-2 border text-center">{getColumnValue(data[column?.accessor], column.accessor)}</td>)}
						{showActions && <div className="flex w-full">
						 <Select
								options={getActionTypes(data.deactivated)}
								isSearchable={false}
								className='w-full'
								onChange={(selected) => 
									setSelectedAction({ id: data.id, value: selected.value})
								}
							/>
							{selectedAction?.id === data.id && 
							<div className="flex mt-2">
								<Button
									className="ml-3 px-2"
									translationKey="GRID.SAVE"
									variant="primary"
									isLoading={isLoading}
									disabled={isLoading}
									onClick={() =>{
										onSave({ data, id: data.id, value: selectedAction.value})
										!isLoading && setSelectedAction()
									}}
								/>
								<Button
									className="ml-3 px-2"
									translationKey="GRID.CANCEL"
									variant="secondary"
									disabled={isLoading}
									onClick={() => setSelectedAction()}
								/>
							</div>
							}
						</div>}
					</tr>
				)
			})}
		</tbody>
	</table>
}


