import { AnimationWrapper, Typography } from "../../component"
import contactUs from '../../assets/images/contact-us.jpg'

export const Contact = () => {
  return  (
    <AnimationWrapper>
    <div className="my-4 container mx-auto relative px-6">
      <div className="flex flex-col lg:flex-row justify-center">
      <Typography
          translationKey="CONTACT_US.LABEL"
          variant="h1"
          className="text-4xl lg:text-8xl mb-6 text-center lg:hidden"
        />
        <img src={contactUs} alt="About Tradeskonnect" className="rounded-lg max-h-[400px] px-6 lg:px-0 mb-6 xl:mb-0 xl:max-h-[500px]"/>
        <div className="flex flex-col xl:ml-12 px-6 xl:px-0 xl:mt-20">
          <Typography
          translationKey="CONTACT_US.LABEL"
          variant="h1"
          className="text-2xl xl:text-6xl text-gray-600 lg:block hidden"
        />
            <div className="flex flex-col mt-6">
              <Typography className="mb-6" variant="h3" translationKey="CONTACT_US.CALL_US_AT"/>
              <Typography variant="h3" translationKey="CONTACT_US.EMAIL_US_AT"/>
            </div>
        </div>
      </div>
    </div>
  </AnimationWrapper>
  )
}