import { createContext, useContext, useMemo, useState } from "react";
import { useGetAuthStatus } from "../../common/query";

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {

  const { data: authStatus, isLoading } = useGetAuthStatus()
  const [updatedAuthStatus, setUpdatedAuthStatus] = useState({})

  const value = useMemo(() => ({
    isAuthenticated: updatedAuthStatus.authenticated || authStatus?.authenticated, 
    vendorId: updatedAuthStatus?.id || authStatus?.id,
    isAdmin: updatedAuthStatus.isAdmin || authStatus?.isAdmin,
    isLoading,
    setUpdatedAuthStatus,
  }), [
    isLoading,
    updatedAuthStatus,
    authStatus])
    
  return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
  )
}

export const AuthConsumer = AuthContext.Consumer;

export const useAuth = () => useContext(AuthContext)