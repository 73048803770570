
import React, { useEffect, useState } from 'react';
import "react-multi-carousel/lib/styles.css";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useVerifyVendor } from './query';
import { Loader } from '../../component/loader';
import { AnimationWrapper, Button, Error404Page, Typography } from '../../component';
import { CheckCircle } from 'react-feather';
import { ResendInviteLinkForm } from './resend-invite-form.component';



export const VerifyAccount = () => {
  const [expired, setExpired] = useState();
  const [verified, setVerified] = useState();
  const [searchParams] = useSearchParams()
  const [sendNewLink, setSendNewLink] = useState(false);

  const navigate = useNavigate()

  const { mutate, isLoading } = useVerifyVendor();
  const token = searchParams.get('token');

  useEffect(() => {
      mutate(token, {
        onError: (error) => setExpired(error.response.data.expired),
        onSuccess: () => setVerified(true)
      })
  }, [token, mutate])


  if (isLoading) {
    return <div className='flex justify-center items-center h-full'>
        <Loader/>
    </div>
  }

  return (
  <AnimationWrapper>

  <div className='bg-gray-200 mb-16 md:px-0 h-full'>
    <div className="h-[220px] bg-home-background bg-center md:bg-right">
      <div className="h-full bg-opacity-60 bg-blue-500 flex flex-col justify-center items-center bg-center">
        <div className="pt-6 px-2 md:px-8 container mx-auto relative rounded-t-lg">
          <Typography
            variant='h1'
            translationKey='ACCOUNT_VERIFICATION.HEADER'
            className='text-white text-center'
          />
        </div>
      </div>
    </div>

    {
      expired && sendNewLink && (
        <div className='flex justify-center items-center w-full mt-16'>
          <ResendInviteLinkForm />
        </div>
      )
    }


    {expired && !sendNewLink && <div className='flex justify-center items-center w-full mt-16'>
          <Error404Page
            message="ACCOUNT_VERIFICATION.LINK_EXPIRED"
            onButtonClick={() => setSendNewLink(true)}
            buttonText='ACCOUNT_VERIFICATION.REQUEST_FOR_NEW_LINK'/>
        </div>}
    {verified && !expired && <div className="h-full flex mt-16 justify-center">
              <div className="flex items-center flex-col px-6 py-5">
                <CheckCircle  size={50} className="text-green-200" />
                <Typography
                  variant='h5'
                  className='px-6 mt-4 text-center'
                  translationKey="ACCOUNT_VERIFICATION.ACCOUNT_VERIFIED"
                />
                <Button onClick={() => navigate('/login')} translationKey="ADD_LISTING.LOGIN" className='p-3 bg-red-600 text-white rounded-lg mt-3'/>
              </div>
          </div>}
  </div>
  </AnimationWrapper>

  )
}