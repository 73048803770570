import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from 'react-intl';

import { mergeClassName } from '../../utils'
import { buttonVariants } from './button.cva';
import { Loader } from "../loader";

export const Button = ({ isLoading, disabled, type, variant, className, translationKey, onClick, size, isCondensed }) => {

  return (
    <button type={type} onClick={onClick} disabled={disabled} className={mergeClassName(buttonVariants({ variant, size, isCondensed }), className)}>
      {isLoading && <Loader size="20"/>}
      <FormattedMessage id={translationKey}/>
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(["primary", "ghost", "secondary", "link", "subduedGhost", "destructivePrimary", "destructiveGhost"]),
  size: PropTypes.oneOf(["md", "lg", "sm"]),
  isCondensed: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  translationKey: PropTypes.string.isRequired,
};

Button.defaultProps = {
  variant: "md",
  color: "default",
};
