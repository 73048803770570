import classNames from "classnames"
import { useState } from "react";
import { Eye, EyeOff } from "react-feather";
import { useIntl } from "react-intl";
import { Typography } from "../typography";

export const InputField = ({
  className,
  defaultValue,
  label,
  labelClassName,
  orientation = 'vertical',
  inputClassName,
  error,
  register,
  required,
  type = 'text',
  placeholder= 'Type here',
  textareaValue,
  allowedChars,
  disabled,
  isLoading,
  startIcon = false,
  hint,
}) => {

  const {formatMessage } = useIntl()
  const [showPassword, setShowPassword] = useState(false)
  const labelTranslation = label && formatMessage({id: isLoading ? 'LOADING' : label })


  if (type === 'submit') {

    return  <input disabled={disabled} type={type} value={labelTranslation} className={classNames('h-10', {
      'cursor-not-allowed bg-gray-500': disabled,
      'cursor-pointer bg-gray-50': !disabled,
    }, inputClassName)} />
  }

  if (type === 'radio' || type === 'checkbox') {
   return <div>
    <div className={classNames('flex flex-row items-center', className)}>
      <input {...register} disabled={disabled} type={type} className={classNames({
        'cursor-not-allowed bg-gray-500': disabled,
        'cursor-pointer bg-gray-50': !disabled,
      }, inputClassName)} />
     { labelTranslation && <label className={classNames('ml-2',labelClassName)}>
        {labelTranslation}
      </label>}
    </div>
    { error && <span className="text-red-300 text-xs">{error}</span>}
   </div>
   
  }

  return <div className={classNames('flex flex-row', className, {'flex-col': orientation === 'vertical'})}>
          <div className="flex justify-between items-center">
            <div>
              {required && <span className="text-red-300 text-md mr-1">*</span>}
              <label className={labelClassName}>
              {labelTranslation}
              </label>
            </div>
            {hint && <Typography
              translationKey={hint}
              variant='label'
              className="md:text-[10px] text-[8px] text-red-300"
            />}
          </div>
            <div className="flex flex-col">
              {type === 'textarea' ?
              <div className="flex flex-col">
                <textarea
                {...register}
                placeholder={placeholder}
                disabled={disabled}
                  className={classNames('h-[100px] w-full text-sm mt-2 px-2 border resize-none p-4 rounded-md outline-none focus:border-2', inputClassName)}
                />
                {Boolean(allowedChars) && <div className="flex w-full justify-between items-center mt-1">
                { error && <span className="text-red-300">{error}</span>}
                  <span className={classNames("text-black mr-1 ml-auto", { "text-red-300": textareaValue?.length > allowedChars, "text-green-300":  textareaValue?.length > 0 })}>{textareaValue?.length ?? 0} of {allowedChars}</span>
                </div>}
              </div>
              :
              <>
                <div className="flex-row flex items-center relative">
                    {startIcon && <div className="absolute left-4 bottom-3">
                    {startIcon}
                      </div>}
                    <input
                    disabled={disabled}
                    type={type === 'password' && showPassword ? 'text' : type}
                    {...register}
                    defaultValue={defaultValue} 
                    className={classNames('h-12 leading-10  w-full mt-2 border rounded-md outline-none focus:border-2', inputClassName, {'px-12': startIcon, 'px-2': !startIcon && type !== 'file', 'bg-gray-300 cursor-not-allowed': disabled})}
                    placeholder={placeholder}
                    />
                  {type === 'password' && showPassword && <EyeOff className="cursor-pointer absolute right-2 top-6" size={16} onClick={() => setShowPassword(!showPassword)}/>}
                  {type === 'password' && !showPassword && <Eye className="cursor-pointer absolute right-2 top-6" size={16} onClick={() => setShowPassword(!showPassword)}/>}
                </div>
                { error && <span className="text-red-300 mt-1">{error}</span>}              
              </>
              }
            </div>
        </div>
}