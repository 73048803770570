
export const COLUMNS = [
    { name: 'id', accessor: 'id'},
    { name: 'Email', accessor: 'email'},
    { name: 'vendor Id', accessor: 'vendorId'},
    { name: 'Review completed', accessor: 'hasReviewedVendor'},
    { name: 'Review request', accessor: 'reviewRequestCount'},
    { name: 'Last Review request', accessor: 'lastReviewRequest'},
    { name: 'Deactivated', accessor: 'deactivated'},
    { name: 'Updated', accessor: 'updatedAt'},
    ]
