import { RouteWrapper, useLogout } from "../../common"
import { VendorContextProvider } from "./context/vendor-context"
import { useAuth } from "../../core";
import { useGetAuthedVendorDetails } from "./query";
import { ErrorPage, Loader } from "../../component";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ROUTE_PATHS } from "../../constants";

export const VendorWrapper = ({children }) => {
  const auth = useAuth();
  const { mutate: logout } = useLogout()


  const { data: vendorDetails, isLoading, isError } = useGetAuthedVendorDetails(auth?.vendorId, {
    include: 'reviews'
  })

  const navigate = useNavigate();

  useEffect(() => {
    if (!auth?.isLoading && !auth?.isAuthenticated) { 
      return navigate(ROUTE_PATHS.HOME)
    }

  }, [auth?.isAuthenticated, auth?.isLoading, navigate])
  
  if(isLoading) {
    return <div className="flex justify-center items-center h-screen"><Loader/></div>
  }


  if (isError) {
    return <ErrorPage/>
  }

  const { vendor } = vendorDetails

  // This is for unknown circumstances where vendor may not be available
  if (!vendor) {
    logout()
    auth?.setUpdatedAuthStatus({authenticated: false})
    return navigate(ROUTE_PATHS.HOME)
  }

  return (
    <VendorContextProvider value={{ vendor }}>
      <RouteWrapper>
          {children}
      </RouteWrapper>
    </VendorContextProvider>
)
}