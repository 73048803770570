
import classNames from "classnames";
import ReactStars from "react-rating-stars-component";
import { Typography } from "../typography";
import { Trash } from "react-feather";
import { useState } from "react";
import { Button } from "../button";

export const Ratings = ({ allowTrash = false, showHeader = true, vendorReviews = [], averageRating }) => {
  const [confirm, showConfirm] = useState()

  const handleReviewDelete = (id) => {
    console.log(id);
  }

  return (
    <div className="drop-shadow-lg w-full">
      {showHeader && <>
        <Typography
          translationKey='VENDOR.RATINGS'
          className='p-4'
          variant='h3'
        />
        <div className="border border-gray-200 h-[1px]"/>
      
      </>}

      {Boolean(averageRating) ? <>
        <div className="w-full flex justify-center mt-4">
          <div className="border-t-4 border-t-gold-300 h-[50px] sm:h-[100px] flex justify-center items-center w-[50px] sm:w-[100px] rounded-full bg-gray-200">
            <h1 className='text-xl sm:text-5xl'>{averageRating}</h1>
          </div>
        </div>
        <Typography className='text-center mt-2' translationKey="VENDOR.REVIEW.AVERAGE_RATING"/>

        <div className="border border-gray-200 h-[1px] my-4"/>

        <div className=" w-full">
          <Typography
          translationKey='VENDOR.REVIEWS'
          className='p-4'
          variant='h3'
        />
        <div className="px-4 sm:px-8 min-h-max max-h-[300px] overflow-auto">
            {vendorReviews.map((review, index) => {
              return (
                <div key={review.id}>
                  <h1 className="font-extrabold">{review.user.firstName} {review.user.lastName}</h1>
                  <div className="flex justify-between flex-col md:flex-row mt-2 last:mb-4">
                    <p className="font-thin text-xs mb-4 w-full">{review.reviewComment || "-" }</p>
                      <div className="w-full mb-4 justify-end flex">
                        <ReactStars
                          count={review.starCount}
                          size={12}
                          color="#ffd700"
                          edit={false}
                          classNames="order-2"
                        />
                        {allowTrash && confirm !== review.id && 
                            <Trash size={16} className="ml-4 cursor-pointer" onClick={() => showConfirm(review.id)}/>
                        }
                      </div>
                  </div>
                    {allowTrash && confirm === review.id && 
                          <div className="flex flex-col items-center mt-4">
                          <Typography
                            className='mb-2'
                            translationKey='VENDOR.REVIEW.DELETE_TITLE'
                          />
                          <div className="flex">
                            <Button
                              translationKey="VENDOR.REVIEW.ACTIONS.CONTINUE"
                              size='md'
                              variant='secondary'
                              className='h-9 w-24 md:mt-0 mt-4 mb-2 md:ml-3'
                              onClick={() => handleReviewDelete(review.id)}
                              isLoading={false}
                              disabled={false}
                            />
                            <Button
                              translationKey="VENDOR.CANCEL"
                              size='md'
                              variant='destructivePrimary'
                              className='h-9 md:mt-0 mt-4 md:ml-3 w-24'
                              disabled={false}
                              onClick={() => showConfirm(null)}
                            />
                          </div>
                      </div>
                    }
                  <div className={classNames("border border-gray-200 h-[1px] my-4", { 'hidden': index === vendorReviews.length - 1})}/>
                </div>
              )
            })}
        </div>
        </div>
      </>
      :
      <Typography
        translationKey='VENDOR.NO_RATINGS'
        className='p-4 text-center'
        variant="label"

        />
    }

    </div>
  ) 
}