import {
  Menu,
} from "react-feather";

import { MobileSlide } from "./mobile-slider.component";
import { TradesKonnectLogo } from "./trades-konnect.component";
import { Button } from "../../component";
import { useLogout } from "../query";
import { Loader } from "../../component";
import { useAuth } from "../../core";
import { vendorRoutes, AdminRoutes } from "../../utils";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants";

export const AuthedHeader = ({ showSideMenu, setShowSideMenu }) => {
  const { mutate: logout, isLoading } = useLogout()
  const auth = useAuth()
  const navigate = useNavigate()

  if(isLoading) {
      return <div className="w-full h-screen flex justify-center items-center"><Loader/></div>
  }

  const handleLogout = () => {
    logout()
    auth?.setUpdatedAuthStatus({authenticated: false})
    navigate(ROUTE_PATHS.HOME)
  }

  return (
    <div className="h-[100px] py-6 bg-blue-600 px-2 lg:px-0 sticky top-0 z-50">
      <nav className="mx-auto h-full flex items-center md:px-8">
        <div className="flex items-center flex-1 mb-4 mt-4">
          <TradesKonnectLogo className="text-white"/>
        </div>
        <button
          onClick={() => setShowSideMenu(!showSideMenu)}
          className="outline-none md:hidden"
        >
          <Menu size={30} className="pr-1" color="white" />
        </button>
        <Button
          onClick={handleLogout}
          translationKey="LOGOUT"
          variant="destructivePrimary"
          className="px-2 py-1 ml-12 hidden md:block"
        />
      </nav>
      <MobileSlide
        showlogButton={auth?.isAuthenticated}
        handleLogout={handleLogout}
        setShowSideMenu={setShowSideMenu} 
        menuLinks={auth?.isAdmin ? AdminRoutes : vendorRoutes} 
        showSideMenu={showSideMenu}
      />
    </div>
  );
};
