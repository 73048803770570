import { motion } from 'framer-motion'
import { Typography, Filter } from "../../component";
import { AnimationWrapper } from "../../component/animation-wrapper";
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../constants';
import { ChevronDown } from 'react-feather';
import { VendorSection } from './vendors-section.component';
import { useRef, useState } from 'react';
import { useGetTopVendors } from '../../common/query';
import { HowItWorks } from './how-it-works.component';
import { VendorMarketing } from './vendor-marketing.component';

export const Home = () => {
  const [filters, setFilters] = useState()
  const navigate = useNavigate();
  const vendorSectionRef = useRef();
  const topVendorsSectionRef = useRef();

  const { data, isError } = useGetTopVendors()

  const scrollToVendorSection = (ref) => {
    ref?.current?.scrollIntoView({behavior: "smooth"})
  }

  const searchVendors = () => {

    const searchParams = new URLSearchParams()
    for ( const filter in filters) {
      searchParams.append(filter, filters[filter])
    }

    navigate(`/${ROUTE_PATHS.LISTINGS}?page=1&${searchParams}`)
  }

  const reset = () => {
    setFilters(prev => ({...prev, page: 1, category: '', keywords: '', location: ''}))
  }

  const hasTopVendors = Boolean(data?.vendors.length && !isError)
  return (
    <AnimationWrapper>
    <div className="w-full">
      <div className="md:h-screen bg-home-background bg-center md:bg-right">
        <div className="h-full flex bg-opacity-60 bg-blue-500 justify-center md:items-center bg-center">
          <div>
          <motion.div animate={{ y: 30 }} initial={{ y: -30 }} exit={{ y: 30 }}
  transition={{ ease: "easeOut", duration: 2 }} className="flex justify-center items-center flex-col">
              <Typography
                translationKey="HOME.CONNECTING_VENDORS_WITH_CLIENTS"
                variant="h1"
                className="text-white text-2xl md:text-5xl text-center mb-4 md:mb-10 font-black"
              />
              <Typography
                translationKey="HOME.CONNECT_WITH_VENDOR"
                variant="label"
                className="text-white text-md md:text-2xl text-center md:px-0 px-6 mb-10 font-thin md:w-[60%]"
              />
          </motion.div>
           <div className='mt-6 p-4 bg-gray-800 rounded-lg opacity-80 mx-4'>
            <Filter filters={filters} getFilterValue={(value) => setFilters({...filters, ...value})} reset={reset} refetch={searchVendors} />
           </div>
            <div className='flex justify-center mt-16'>
              <ChevronDown size={40} onClick={() => scrollToVendorSection(vendorSectionRef.current ? vendorSectionRef : topVendorsSectionRef)} className='text-white animate-pulse cursor-pointer'/>
            </div>
          </div>
        </div>
      </div>
      {hasTopVendors && <VendorSection vendors={data.vendors} ref={vendorSectionRef} />}
      <HowItWorks ref={topVendorsSectionRef} hasTopVendors={hasTopVendors}/>
      <VendorMarketing/>
    </div>
    </AnimationWrapper>

  );
};

