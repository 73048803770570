import { PROMOTION_TYPES } from "../../../../utils"

export const COLUMNS = [
    { name: 'id', accessor: 'id'},
    { name: 'Email', accessor: 'email'},
    { name: 'Phone Number', accessor: 'contactNumber'},
    { name: 'Slug', accessor: 'slug'},
    { name: 'Verified', accessor: 'verified'},
    { name: 'Deactivated', accessor: 'deactivated'},
    { name: 'Created', accessor: 'createdAt'},
    { name: 'Updated', accessor: 'updatedAt'},
    ]

export const ACTION_TYPES = [
    { label: 'Recommend', value: PROMOTION_TYPES.recommended},
    { label: 'Deactivate', value: 'deactivate' }, // true
    { label: 'VOM', value: 'VOM'},
    { label: 'Top Vendor', value: 'tov'},
    ]