import axios from "axios"
import { getEnvApi } from "../utils"

export const baseApi = getEnvApi(process.env.NODE_ENV)

export const apiRoutes = {
  cities: '/cities',
  feedback: 'feedback',
  services: '/services',
  topVendors: '/top-vendors',
  contactVendor: '/contact-vendor',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  addVendor: '/add-vendor',
  verifyAccount: '/verify-account',
  resendInviteLink: '/resend-invite-link',
  login: '/login',
  vendors: '/vendors',
  vendor: '/vendor',
  'auth-status': '/auth-status',
  authVendors: '/auth/vendors',
  logout: '/auth/logout',
  admin: {
    recommendVendor: '/admin/create-promotion',
    promotions: '/admin/promotions',
    users: '/admin/users',
    sendMail: '/admin/send-mail',
    resetUserOrVendor: '/admin/user-vendor-reset'
  }
}

  export const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: baseApi
 })

 export const fetcher = async (uri, payload, method = 'GET', params = null ) => {
  return await fetch(uri + params && new URLSearchParams({...params}), {
    method,
    credentials: 'include',
    mode:'same-origin',
    body: {...payload}
  }).then(async res => await res.json())
 } 
