import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputField, SelectField } from "../../component/form"
import { useFormSchema } from './schema';
import { Loader, TermsAndCoditionLink } from '../../component';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { mapVendorUpdateDetails, toastUtil } from '../../utils';
import { useServices } from '../../common/query';
import { ROUTE_PATHS } from '../../constants';
import { AddressAutoComplete } from './AddressAutoComplete'

export const ListingForm = ({
  className, 
  footerClassName,
  isEditing, 
  defaultValues, 
  mutateFn,
  mutationLoading,
  onSuccess,
 }) => {

  const existingAddress = defaultValues?.address
  const existingAddressParts = defaultValues && JSON.parse(defaultValues?.addressParts)
  const [address, setAddress] = useState( isEditing ? existingAddress : '')
  const [addressParts, setAddressParts] = useState(existingAddressParts)
  const [addressError, setAddressError] = useState(false)
  const { data: services, isLoading: isLoadingServices } = useServices();
  const { schema } = useFormSchema(isEditing)

  const existingServices = useMemo(() => [...services?.services ?? []].filter(option => defaultValues?.services?.includes(option.label)), [services?.services, defaultValues?.services])

  const { register, formState: { errors, isDirty }, handleSubmit, watch, control } = useForm({
    resolver: yupResolver(schema),
    values: {...defaultValues, services: existingServices || []}
  });

  const descriptionValue = watch('description')

  const handleMutate = (values) => {

    mutateFn({...values, address, services: values?.services.map(service => service.label), addressParts: addressParts ?? {
      city: address
    }, socialMedia: JSON.stringify(values?.socialMedia)}, {
     onError: (error) => {
       toastUtil({message: error.response.data.message, position: 'top-center', theme: 'failure'})
     },
     onSuccess: () => {
      onSuccess();
      window.scrollTo(0, 0)
     }
    })
 }

 useEffect(() => {
    if (address) {
      setAddressError('')
    }
 }, [address])

 const onSubmit = async(values) => {

  if (!address) {
    setAddressError(true)
   } else {
     setAddressError(false);

     if (isEditing) {
     return mutateFn({id: defaultValues.id, 
        values: {
        ...mapVendorUpdateDetails(values, addressParts),
        }}, {
        onSuccess: () => {
          onSuccess()
          
          toastUtil({message: 'Profile updated', theme: 'success', position: 'top-center'})
        },
        onError: (error) => {
      if (isEditing && error?.response?.status === 401) {
          window.location.replace(ROUTE_PATHS.HOME)
        }
          toastUtil({ message: error.response.data.message, position: 'top-center', theme: 'failure' })
        }
      })
    }

     if (values.logo) {
       const file = values.logo?.[0]
       const reader = new FileReader();

       reader.onloadend = () => {
        const base64String = reader.result.replace('data:', '').replace(/^.+,/, '')
        const updated = {...values, logo: base64String }
        handleMutate(updated)
       }

       reader.readAsDataURL(file)
     } else {
       handleMutate(values)
     }
     
   }
}

const disableSubmitButton = mutationLoading || (isEditing && !isDirty)

  return (
    <form className="h-full" onSubmit={handleSubmit(onSubmit)}>
      <fieldset disabled={isLoadingServices || mutationLoading}>
          <div className={classNames(className)}>
            <InputField
              register={{...register('businessName')}}
              label="ADD_LISTING.FIELD_LABELS.NAME"
              disabled={isEditing}
              className="py-4"
              inputClassName='bg-gray-50'
              error={errors?.businessName?.message}
              required
            />

          <InputField
              register={{...register('description')}}
              label="ADD_LISTING.FIELD_LABELS.DESCRIPTION"
              className="py-4"
              inputClassName='bg-gray-50'
              error={errors?.description?.message}
              required
              placeholder='Ensure to create a compelling description of what you do'
              type='textarea'
              allowedChars={300}
              textareaValue={descriptionValue}
            />

            <SelectField
              className=" mt-4 rounded-lg outline-none bg-red w-full"
              label="ADD_LISTING.FIELD_LABELS.SERVICES"
              selectClassName='bg-gray-50'
              placeholder='You can select up to three services'
              disabled={isLoadingServices}
              options={services?.services}
              error={errors?.services?.message}
              existingServices={existingServices}
              control={control}
              required
            />

            <div className='my-4'>
              <AddressAutoComplete
              required 
              label="ADD_LISTING.FIELD_LABELS.ADDRESS" 
              inputClassName='bg-gray-50' 
              error={addressError}
              address={address}
              setAddress={setAddress}
              setAddressParts={setAddressParts}
              />
            </div>
            <InputField
              disabled={isEditing}
              register={{...register('email')}}
              label="ADD_LISTING.FIELD_LABELS.EMAIL"
              className="py-4"
              inputClassName='bg-gray-50'
              error={errors?.email?.message}
              required
            />

            <InputField
              register={{...register('contactNumber')}}
              label="ADD_LISTING.FIELD_LABELS.PHONE_NUMBER"
              className="py-4"
              inputClassName='bg-gray-50'
              error={errors?.contactNumber?.message}
              required
              type='tel'
            />
            { !isEditing &&
              <>  
                  <InputField
                  register={{...register('password')}}
                  label="ADD_LISTING.FIELD_LABELS.PASSWORD"
                  className="py-4"
                  inputClassName='bg-gray-50'
                  error={errors?.password?.message}
                  required
                  type='password'
                />
                <InputField
                  register={{...register('confirmPassword')}}
                  label="ADD_LISTING.FIELD_LABELS.CONFIRM_PASS"
                  className="py-4"
                  inputClassName='bg-gray-50'
                  error={errors?.confirmPassword?.message}
                  required
                  type='password'
                />
              </>
            }
            {!isEditing && <InputField
              register={{...register('logo')}}
              label="ADD_LISTING.FIELD_LABELS.LOGO"
              className="py-4 flex-1"
              type='file'
              required
              inputClassName='bg-gray-50'
              error={errors?.logo?.message}
            />}
          <InputField
            register={{...register('socialMedia.facebook')}}
            label="ADD_LISTING.FIELD_LABELS.FACEBOOK"
            className="py-4"
            inputClassName='bg-gray-50'
            hint={!isEditing && 'HINTS.CANNOT_BE_CHANGED' }
            disabled={isEditing && defaultValues?.socialMedia?.facebook}
          />
          <InputField
            register={{...register('socialMedia.twitter')}}
            label="ADD_LISTING.FIELD_LABELS.TWITTER"
            className="py-4"
            placeholder="vendorname without the @ sign"
            inputClassName='bg-gray-50'
            hint={!isEditing && 'HINTS.CANNOT_BE_CHANGED' }
            disabled={isEditing && defaultValues?.socialMedia?.twitter}
          />
          <InputField
            register={{...register('socialMedia.instagram')}}
            label="ADD_LISTING.FIELD_LABELS.INSTAGRAM"
            className="py-4"
            placeholder="vendorname without the @ sign"
            inputClassName='bg-gray-50'
            hint={!isEditing && 'HINTS.CANNOT_BE_CHANGED' }
            disabled={isEditing && defaultValues?.socialMedia?.instagram}

          />
        {!isEditing && <TermsAndCoditionLink register={register} errors={errors} disabled={mutationLoading}/>}
        </div>
        <div className="border border-gray-200 h-[1px]"/>

        <div className={classNames("mt-4 flex-col md:flex-row justify-end flex items-center", footerClassName)}>
          {mutationLoading && <div className='mr-4 md:mb-0 mb-4'><Loader size='30'/></div>}
          <InputField 
          disabled={disableSubmitButton}
          isLoading={mutationLoading}
          type="submit" inputClassName='w-full md:w-auto text-white px-4 h-12 rounded-lg bg-red-500' label={isEditing ? 'VENDOR.PROFILE.UPDATE_PROFILE' : 'ADD_LISTING.LABEL'}/>
        </div>
    </fieldset>
  </form>
  )
}