import axios from 'axios';
import { useMutation } from 'react-query'
import {baseApi, apiRoutes} from '../../../api'

export const usePostListing = () => {
  return useMutation(
    async (listing) => {
      delete listing.confirmPassword

      const { data } = await axios.post(baseApi + apiRoutes.addVendor, {...listing, addressParts: JSON.stringify(listing.addressParts), isVendorUser: true})

      return data;
    }
  )
}

