import { Eye, EyeOff } from "react-feather"
import { Button } from "../../../component"

export const PreviewToggle = ({ setIsPreviewing, isPreviewing }) => {
    return <div className="py-4 flex justify-end items-center">
    {isPreviewing ? <EyeOff size={20} className="mr-2"/> : <Eye size={20} className="mr-2"/>}
    <Button
      translationKey={isPreviewing ? "VENDOR.EXIT_PREVIEW" : "VENDOR.PREVIEW_PROFILE"}
      variant='link'
      onClick={() => setIsPreviewing(!isPreviewing)}
    /> 
  </div>
}