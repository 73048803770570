import { useIntl } from "react-intl";
import * as yup from "yup";
import "yup-phone-lite";


export const useForgotPasswordSchema = () => {

  const intl = useIntl()
   const schema = yup.object({
    email: yup.string().email().required(
      intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.EMAIL_REQUIRED'})
    )
  }).required();


  return { schema }
}