import { createContext, useMemo } from "react"
import { useContextFallback } from "../../../common";


const VendorContext = createContext()

export const VendorContextProvider = ({ value, children }) => {
  const { vendor } = value 


  const modifiedValue = useMemo(() => {
    return {
      vendor: vendor  
    }
  }, [vendor])
  
  return (
    <VendorContext.Provider value={modifiedValue}>
      {children}
    </VendorContext.Provider>
  )
}

export const useVendorContext = () => useContextFallback(VendorContext);