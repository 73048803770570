import { useMutation, useQuery, useQueryClient } from 'react-query'
import {apiRoutes, axiosInstance} from '../../api'

export const useLogin = () => {

  return useMutation(async (details) => {
      const { data } = await axiosInstance.post(apiRoutes.login, {...details})
      return data;
    },
  )
}

export const useLogout = () => {
  const queryClient = useQueryClient()

return useMutation(async () => {
      const { data } = await axiosInstance.post(apiRoutes.logout)
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('authStatus')
      }
    })
}

export const useGetVendorDetails = (slug, params) => {

  return useQuery({
    queryKey: ['vendor-details'],
    queryFn: async () => {
      const { data } = await axiosInstance.get(`${apiRoutes.vendors}/${slug}`, { params })

      return data;
    },
  },
   {
    enabled: !!slug
   })
}

export const useGetAuthStatus = () => {

  return useQuery({
    queryKey: ['authStatus'],
    queryFn: async () => {
      const { data } = await axiosInstance.get(apiRoutes['auth-status'])
      return data;
    },
    // retry: false
  })
}

export const useGetCities = () => {

  return useQuery({
    queryKey: ['cities'],
    queryFn: async () => {
      const { data } = await axiosInstance.get(apiRoutes.cities)

      return data;
    },
    retry: false
  })
}
