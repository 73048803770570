
import { useQuery, useMutation } from 'react-query';
import { apiRoutes, axiosInstance } from '../../../../api';

export const useGetUsers = () => {

 return useQuery(['users'], async () => {
    const { data } = await axiosInstance.get(apiRoutes.admin.users);

    return data;
  });
}

export const useAdminUpdateUserMutation = () => {

  return useMutation(async (details) => {
      const { data } = await axiosInstance.patch(apiRoutes.admin.users + '/' + details.userId, {...details})
      return data;
    },
  )
}

export const useAdminSendReviewMailMutation = () => {

  return useMutation(async (details) => {
      const { data } = await axiosInstance.post(apiRoutes.admin.sendMail, {...details})
      return data;
    },
  )
}