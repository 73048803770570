import { Link } from 'react-router-dom'
import ErrorMessageImage from '../../assets/images/error-page.jpg'
import { Button } from '../button'
import { Typography } from '../typography'
import { ROUTE_PATHS } from '../../constants'

export const ErrorPage = () => {

  return <div className="h-full flex flex-col justify-center items-center mt-5">
    <img src={ErrorMessageImage} alt='Error page' className='mt-12 md:mt-0 md:h-[300px] px-6 mb-6'/>
      <Typography
        translationKey='ERROR_PAGE.ERROR_OCCURRED'
        variant='p'
        className='text-center px-4 text-lg max-w-[700px]'
      />
       <Link to={ROUTE_PATHS.VENDOR.DASHBOARD}>
        <Button
          translationKey='ERROR_PAGE.REFRESH'
          className="rounded-lg px-4 py-2 mt-4 mb-12"
          variant='destructivePrimary'
        /> 
    </Link>
  </div>
}