import { PROMOTION_TYPES } from "../../../../utils";

export const ACTION_VALUES = {
    VOM: 'VOM',
    TOV: 'tov',
    RMV: 'rvm',
    DEACTIVATE: 'deactivate',
    REACTIVATE: 'reactivate'
}

export const ACTION_LABELS = {
    RECOMMEND: 'Recommend',
    VOM: 'VOM',
    TOV: 'Top Vendor',
    DEACTIVATE: 'Deactivate',
    REACTIVATE: 'Reactivate',
    RESEND_VER_MAIL: 'Resend Ver. Mail'
}

export const getActionTypes = (isDeactivated) => {
    return [
    { label: ACTION_LABELS.RECOMMEND, value: PROMOTION_TYPES.recommended},
    { label: ACTION_LABELS.VOM, value: ACTION_VALUES.VOM},
    { label: ACTION_LABELS.TOV, value: ACTION_VALUES.TOV},
    { label: ACTION_LABELS.RESEND_VER_MAIL, value: ACTION_VALUES.RMV},
    isDeactivated ? { label: ACTION_LABELS.REACTIVATE, value: ACTION_VALUES.REACTIVATE } : { label: ACTION_LABELS.DEACTIVATE, value: ACTION_VALUES.DEACTIVATE },
    ]
}

export const getIsVendorRecommended = (promotions = []) => Boolean(promotions.find(promotion => promotion.isActive && promotion.type === PROMOTION_TYPES.recommended))
