import { useQuery, useMutation } from 'react-query';
import { apiRoutes, axiosInstance } from '../../../../api';

export const useGetPromotions = () => {

 return useQuery(['promotions'], async () => {
    const { data } = await axiosInstance.get(apiRoutes.admin.promotions);

    return data;
  });
}

export const useAdminUpdatePromotionMutation = () => {

  return useMutation(async (details) => {
      const { data } = await axiosInstance.patch(apiRoutes.admin.promotions + '/' + details.promotionId, {...details})
      return data;
    },
  )
}