
import React, { useState } from 'react';
import "react-multi-carousel/lib/styles.css";
import { useNavigate, useParams } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";

import { usePostReviewVendorMutation } from './query';
import { Loader } from '../../component/loader';
import { AnimationWrapper, Error404Page, InputField, Typography } from '../../component';
import { useReviewVendorSchema } from './schema';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetVendorDetails } from '../../common/query';
import { toastUtil } from '../../utils';
import { ROUTE_PATHS } from '../../constants';



export const ReviewVendor = () => {
	const { slug, reviewLink } = useParams();
	const [isReviewSaved, setIsReviewSaved] = useState()
	const navigate = useNavigate()
	const schema = useReviewVendorSchema()
	const { data: vendorDetails, isLoading: isLoadingVendorDetails, isError } = useGetVendorDetails(slug)
	const { handleSubmit, formState: { errors }, watch, register, control } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { reviewComment: '', starCount: 0 }
	})
	
  const { mutate, isLoading } = usePostReviewVendorMutation();
  
const onSubmit = (values) => {
	mutate({ slug, reviewLink, payload: { ...values }}, {
		onSuccess: () => {
			setIsReviewSaved(true)
			toastUtil({message: 'Review saved, thank you!', theme: 'success', duration: 5000})
			setTimeout(() => {
				navigate(ROUTE_PATHS.LISTINGS)
			}, 5000);
		},
		onError: (error) => toastUtil({message: error.response.data.message, theme: 'failure', duration: 5000})
		
	})

}

	const starCount = watch('starCount')
	const reviewComment = watch('reviewComment')

	
  if (isLoadingVendorDetails) {
    return <div className='flex justify-center items-center h-full'>
        <Loader/>
    </div>
  }

	if(isError || !vendorDetails.vendor) {
		return <div className='flex justify-center items-center h-full'>
			<Error404Page/>
		</div>
	}

	const { vendor } = vendorDetails
  return (
	<AnimationWrapper>
  <div className='bg-gray-200 mb-16 md:px-0 h-full'>
    <div className="h-[220px] bg-home-background bg-center md:bg-right">
      <div className="h-full bg-opacity-60 bg-blue-500 flex flex-col justify-center items-center bg-center">
        <div className="pt-6 px-2 md:px-8 container mx-auto relative rounded-t-lg">
          <Typography
            variant='h1'
            translationKey='REVIEW_VENDOR.HEADER'
						values={{ vendorName: vendor?.name }}
            className='text-white text-center'
          />
        </div>
      </div>
    </div>

    <form onSubmit={handleSubmit(onSubmit)} className='container mx-auto px-6 sm:min-w-[300px] max-w-[500px] my-6'>
			<div className='flex flex-col'>
				<Typography variant='label' className="mb-2 text-md" translationKey='ADD_LISTING.FIELD_LABELS.REVIEW_STARS' values={{ vendor: vendor?.name}}/>
				<Controller
					name="starCount"
					control={control}
					defaultValue={starCount}
					render={({ field }) => {
						return (
							<ReactStars
							count={5}
							value={starCount}
							size={60}
							half
							color="gray"
							color2={'#ffd700'}
							{...field}
						/>
						)
					}}
				/>
			</div>
			<InputField
				register={{...register('reviewComment')}}
				label="ADD_LISTING.FIELD_LABELS.REVIEW_COMMENT"
				className="py-6"
				inputClassName='bg-gray-50'
				error={errors?.reviewComment?.message}
				type='textarea'
				textareaValue={reviewComment}
			/>

			<InputField 
				disabled={isReviewSaved || !starCount || isLoading}
				isLoading={isLoading}
				type="submit" inputClassName='w-full md:w-auto ml-auto text-white px-4 h-12 rounded-lg bg-red-500' label='REVIEW_VENDOR.SUBMIT'
			/>

		</form>
  </div>
  </AnimationWrapper>
  )
}