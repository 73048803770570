import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Mail } from "react-feather";
import { InputField } from "../../component/form"
import { AnimationWrapper, Button, Typography } from "../../component";

import { toastUtil } from "../../utils";
import { useForgotPasswordSchema } from "./schema";
import { Alert } from "../../component/alert";
import { useForgotPasswordMutation } from "./query";
import { useState } from "react";

export const ForgotPassword = () => {
	const [showAlert, setShowAlert] = useState(false)
	const { mutate, isLoading } = useForgotPasswordMutation();
	const { schema } = useForgotPasswordSchema()

	const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (values) => {
    mutate({ ...values }, {
      onError: (err => {
				toastUtil({message: err.response?.data?.message, theme: 'failure'})
				setShowAlert(false)
			}),
      onSuccess: () => setShowAlert(true)
    })

  }
  return <AnimationWrapper>

		<div className=" bg-gray-100 py-6">
       <div className="flex justify-center">
        <Typography
              translationKey="FORGOT_PASSWORD.LABEL"
              variant="h2"
          />
       </div>
      <div className="md:w-4/12 w-11/12 mt-8 mb-5 pb-4 rounded-lg border border-neutral-100 shadow bg-white h-auto mx-auto">
				{showAlert && <Alert message="FORGOT_PASSWORD.ALERT" iconSize={32} type='info' className='border-1'/>}
        <form className="relative h-full p-4" onSubmit={handleSubmit(onSubmit)}>
          <InputField
          register={{...register('email')}}
          label="ADD_LISTING.FIELD_LABELS.EMAIL"
          className="py-4"
          inputClassName='bg-gray-50'
          error={errors?.email?.message}
          startIcon={<Mail className="text-gray-300" />}
          required
        />
        <Button
          translationKey="FORGOT_PASSWORD.RESET"
          className='w-full md:w-auto ml-auto mt-4 mb-4'
          variant='primary'
          size='lg'
          type='submit'
          disabled={isLoading || showAlert}
					isLoading={isLoading}
        />
      </form>
      </div>
  </div>
	</AnimationWrapper>

}