import { Typography } from '../../component'
import classNames from 'classnames'
import { HOW_IT_WORKS_STEPS } from './constant'
import { forwardRef } from 'react'


export const HowItWorks = forwardRef((props, ref) => {
  const { hasTopVendors = false } = props
  return (
    <div ref={ref} className={classNames('w-full flex flex-col h-full mt-4 bg-black opacity-100 py-8', {'bg-white mt-0': !hasTopVendors })}>
      <Typography
          translationKey="HOW_IT_WORKS.LABEL"
          variant="h1"
          className={classNames("text-2xl my-4 text-gray-200 md:text-5xl text-center mb-4 md:mb-2 font-black", { 'text-black': !hasTopVendors })}
        />
      <div className="container my-4 md:my-16 flex md:flex-row flex-col justify-between mx-auto">
        {
          HOW_IT_WORKS_STEPS.map(step => {
            return (
              <div className="flex flex-col items-center flex-1" key={step.id}>
                <img alt={step.title} src={step.imageSource} className="h-16 mt-4 md:mt-0"/>
                <Typography
                    translationKey={`HOW_IT_WORKS.STEPS.${step.title}.LABEL`}
                    variant="h3"
                    className={classNames("my-4 text-gray-200 hover:text-blue-200 transition ease-in duration-300", {"text-black" : !hasTopVendors})}
                  />
                <Typography
                  translationKey={`HOW_IT_WORKS.STEPS.${step.title}.DESCRIPTION`}
                  variant="label"
                  className={classNames("mb-4 md:mb-8 text-md hover:text-white transition ease-in duration-500 text-gray-400 md:max-w-[70%] md:px-0 px-8 text-center", { 'hover:text-black': !hasTopVendors })}
                />
                </div>
            )
          })
        }  
      </div>
       {!hasTopVendors && <div className='w-full h-0.5 bg-gray-100'/> }
    </div>
  )
})