import { useIntl } from "react-intl";
import * as yup from "yup";
import "yup-phone-lite";


export const useContactVendorSchema = () => {

  const intl = useIntl()
   const schema = yup.object({
    email: yup.string().email().required(
      intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.REQUIRED'})
    ),
    firstName: yup.string().required(
      intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.REQUIRED'})
    ),
    lastName: yup.string().required(
      intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.REQUIRED'})
    ),
    agreedToTerms: yup.bool().oneOf([true], intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.AGREE_TO_TERMS'}))
  });


  return { schema }
}