import { useContext } from "react"

export const useContextFallback = (value) => {
  const context = useContext(value)

  if (context === undefined) {
    throw new Error('Components using this context must be children of this context')
  }

  return context
}