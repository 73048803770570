import { useIntl } from "react-intl";
import * as yup from "yup";
import "yup-phone-lite";
import { isFileTypeSupported } from "../../utils";
import { MAX_FILE_SIZE } from "../../constants";


export const useFormSchema = (isEditing) => {

  const intl = useIntl()

  const excludedFields = {
    logo: yup.mixed()
      .test({
        message: intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.FILE_TYPE_NOT_SUPPORTED'}),
        test: (file, context) => {

          const isValidFile = isFileTypeSupported(file?.[0])
          if (!isValidFile) {
             context?.createError();
            }
          return isValidFile;
        }
      })
      .test({
        message: intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.FILE_SIZE_NOT_SUPPORTED'}),
        test: (file, context) => {
          const fileTooLarge = file?.[0]?.size > MAX_FILE_SIZE
          if (fileTooLarge) {
             context?.createError();
            }
          return !fileTooLarge;
        }
      }).required(intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.BUSINESS_LOGO_REQUIRED'})),
    password: yup.string().required(
      intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.PASSWORD_REQUIRED'})
    ).min(4),
  confirmPassword: yup.string()
    .required(intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.CONFIRM_PASS'}))
    .oneOf([yup.ref('password'), null], intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.PASSWORD_MATCH'}))
  }
  
   const schema = yup.object({
    businessName: yup.string()
      .required(intl.formatMessage({
        id: 'ADD_LISTING.FORM_ERRORS.NAME_REQUIRED'
      }))
      .min(4, intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.NAME_SHORT'}))
      .max(30).label(intl.formatMessage({ id: 'ADD_LISTING.FIELD_LABELS.NAME'})),
    email: yup.string().email().required(
      intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.EMAIL_REQUIRED'})
    ),
    socialMedia: yup.object({
      facebook: yup.string(),
      twitter: yup.string(),
      instagram: yup.string(),
    }),
    agreedToTerms: yup.bool().oneOf([true], intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.AGREE_TO_TERMS'})),
    description: yup.string().required(
      intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.DESCRIPTION_REQUIRED'})
      ).min(30, intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.DESCRIPTION_SHORT'}))
      .max(300, intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.DESCRIPTION_LONG'})),
      services: yup.array()
        .min(1, intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.MIN_SERVICES'}))
        .max(3, intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.MAX_SERVICES'}))
        .required(intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.SERVICE_TYPE'})),
      contactNumber: yup.string().phone('CA', intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.PHONE_NUMBER_VALID'}))
      .required(intl.formatMessage({ id: 'ADD_LISTING.FORM_ERRORS.PHONE_NUMBER_REQUIRED'})),
     ...(!isEditing && excludedFields),
  }).required();


  return { schema }
}