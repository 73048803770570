import { useMutation } from 'react-query'
import { apiRoutes, axiosInstance } from '../../../api';


export const useVerifyVendor = () => {

  return useMutation(async (token) => {
      const { data } = await axiosInstance.post(apiRoutes.verifyAccount, { token })
      return data;
    },
  )
}

export const useResendInviteLink = () => {

  return useMutation(async (email) => {
      const { data } = await axiosInstance.post(apiRoutes.resendInviteLink, { email })
      return data;
    },
  )
}