import { useState } from "react";
import {
  Typography,
  Listing as ListingComponent,
  Filter,
  Loader,
  Pagination,
  NoResult,
  AnimationWrapper
} from "../../component";
import { useGetListings } from "../../common/query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PAGINATION_ITEMS_PER_PAGE, ROUTE_PATHS } from "../../constants";

export const Listing = () => {
  const [shouldReset, setShouldReset] = useState(false)
  const [searchParams]= useSearchParams()
  const page = +searchParams.get('page') || 1
  const keywords = searchParams.get('keywords')
  const category = searchParams.get('category')
  const location = searchParams.get('location')

  const navigate = useNavigate()

  const [filters, getFilterValue] = useState({ page, keywords, category, location })
  const { data, isLoading, refetch } = useGetListings({
    pageSize: PAGINATION_ITEMS_PER_PAGE,
    include:'reviews',
    ...filters
  }, shouldReset)


  const searchWithFilterValues = () => {
    setShouldReset(false) // Feels like a hack
    const searchParams = new URLSearchParams()
    for ( const filter in filters) {
      
      if (filters[filter]){
        searchParams.append(filter, filters[filter])
      }
    }

    navigate(`/${ROUTE_PATHS.LISTINGS}?${searchParams}`)
    refetch()
  }

  const reset = async () => {
    setShouldReset(true) // Feels like a hack
    navigate('/'+ ROUTE_PATHS.LISTINGS)
    getFilterValue(prev => ({...prev, page: 1, category: '', keywords: '', location: ''}))
  }

  return (
    <AnimationWrapper>
      <div className="h-[350px] md:[250px] bg-home-background bg-center md:bg-right">
        <div className="h-full bg-opacity-60 bg-blue-500 flex justify-center items-center bg-center">
          <div>
             <div className="w-full flex justify-center">
               <Typography
                translationKey="LISTING.CONNECT"
                variant="h1"
                className="text-white text-2xl max-w-fit md:text-4xl mb-8"
                /> 
             </div>
              <Filter filters={filters} reset={reset} refetch={searchWithFilterValues} getFilterValue={getFilterValue}  />
          </div>
        </div>
      </div>
      <div className="bg-neutral-100 py-16 px-3 min-h-screen">
        {isLoading ?
         <div className="flex justify-center"><Loader/></div>
        :
        <>
          {!data?.vendors?.length ? <NoResult onBtnClick={reset} />
          :
            <>
              <ListingComponent vendors={data?.vendors} />
              {data?.totalCount > PAGINATION_ITEMS_PER_PAGE && <div className="w-full justify-center px-4 md:px-0 flex mt-6 md:mt-[100px]">
                <Pagination
                  getFilterValue={getFilterValue}
                  filters={filters}
                  totalCount={data?.totalCount}
                  itemsPerPage={PAGINATION_ITEMS_PER_PAGE}/>
              </div>}
            </>
          }
        </>
        }
      </div>
    </AnimationWrapper>
  );
};
