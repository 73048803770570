import {
  Route, 
  createBrowserRouter, 
  createRoutesFromElements, 
  Outlet, 
  useNavigate, 
  useLocation 
} from "react-router-dom"
import { useEffect } from "react";

import { ROUTE_PATHS, PROTECTED_ROUTES } from "./constants"
import {
  Contact,
  ReviewVendor,
  PrivacyPolicy,
  VendorPromotion,
  About,
  AddListing,
  Listing,
  Login,
  VendorProfile,
  Home,
  ListinDetails,
  VerifyAccount,
  ResetPassword,
  ForgotPassword,
  VendorGallery,
  VendorReviews, 
  VendorWrapper} from "./pages"
import { I18n, DEFAULT_LOCALES } from "./i18n";
import { useAuth } from "./core";
import { AnimationWrapper, Error404Page, Loader } from "./component";
import ReactGA from 'react-ga'
import { Header, Footer } from "./common";
import {
  Dashboard as AdminDashboard,
  Promotions as AdminPromotions,
  Vendors as AdminVendors,
  Users as AdminUsers,
  AdminWrapper
} from "./pages/admin";
import { TermsAndCondition } from "./pages/terms-and-conditions";
import config from "./config";

 ReactGA.initialize(process.env.REACT_APP_MEASUREMENT_ID);

const Layout = () => {

  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {

    if (config.isProd) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    if (!auth?.isLoading) {
      if (!auth?.isAuthenticated && PROTECTED_ROUTES.includes(location.pathname)) {
        return navigate(ROUTE_PATHS.HOME)
      } 
  
      if (auth?.isAuthenticated) {
        if (location.pathname.includes('vendor') || location.pathname.includes('admin')) {
          return navigate(location.pathname)
  
        } else {
          return navigate(auth?.isAdmin ? ROUTE_PATHS.ADMIN.DASHBOARD : ROUTE_PATHS.VENDOR.DASHBOARD)
        }
      } 
    }
  
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.isAuthenticated, navigate])

  if (auth?.isLoading) {
    return <div className='flex justify-center items-center h-screen'>
        <Loader/>
      </div>
  }

  return (
    <I18n language={"en"} locales={DEFAULT_LOCALES}>
      <div className="w-full flex flex-col h-screen">
          <Header />
            <div className="flex-1">
                <Outlet />
              </div>
          <Footer />
      </div>
    </I18n>
  );
};


export const router = createBrowserRouter(createRoutesFromElements(
  
  <Route element={<Layout />}>
    <Route path={`/${ROUTE_PATHS.HOME}`} element={<Home />} />
    <Route path={`/${ROUTE_PATHS.ABOUT}`} element={<About />} />
    <Route path={`/${ROUTE_PATHS.LOGIN}`} element={<Login />} />
    <Route path={`/${ROUTE_PATHS.PRIVACY}`} element={<PrivacyPolicy />} />
    <Route path={`/${ROUTE_PATHS.TERMS}`} element={<TermsAndCondition />} />
    <Route path={`/${ROUTE_PATHS.LISTINGS}`} element={<Listing />} />
    <Route path={`/${ROUTE_PATHS.CONTACT}`} element={<Contact />} />
    <Route path={`/${ROUTE_PATHS.ADD_LISTING}`} element={<AddListing />} />
    <Route path={`/${ROUTE_PATHS.FORGOT_PASSWORD}`} element={<ForgotPassword />} />
    <Route path={`/${ROUTE_PATHS.RESET_PASSWORD}`} element={<ResetPassword />} />
    <Route path={`/${ROUTE_PATHS.VERIFY_ACCOUNT}`} element={<VerifyAccount />} />
    <Route path={`/${ROUTE_PATHS.REVIEW_VENDOR}`} element={<ReviewVendor />} />
    <Route path={`/${ROUTE_PATHS.LISTINGS_DETAILS}`} element={<ListinDetails/>} />

    {/* Vendor routes */}
    <Route path={`/${ROUTE_PATHS.VENDOR.HOME}`} element={<VendorWrapper />}>
      <Route path={`/${ROUTE_PATHS.VENDOR.DASHBOARD}`} element={<VendorProfile />}/>
      <Route path={`/${ROUTE_PATHS.VENDOR.PROMOTE}`} element={<VendorPromotion />}/>
      <Route path={`/${ROUTE_PATHS.VENDOR.GALLERY}`} element={<VendorGallery />}/>
      <Route path={`/${ROUTE_PATHS.VENDOR.REVIEWS}`} element={<VendorReviews />}/>
    </Route>

    {/* Admin route */}
    <Route path={`/${ROUTE_PATHS.ADMIN.HOME}`} element={<AdminWrapper />}>
      <Route path={`/${ROUTE_PATHS.ADMIN.DASHBOARD}`} element={<AdminDashboard/>}/>
      <Route path={`/${ROUTE_PATHS.ADMIN.PROMOTIONS}`} element={<AdminPromotions />}/>
      <Route path={`/${ROUTE_PATHS.ADMIN.VENDORS}`} element={<AdminVendors />}/>
      <Route path={`/${ROUTE_PATHS.ADMIN.USERS}`} element={<AdminUsers />}/>
    </Route>
    <Route path="*" element={<AnimationWrapper><Error404Page buttonText="ERROR_PAGE.HOME" redirectTo={ROUTE_PATHS.HOME}/></AnimationWrapper>} />
  </Route>

  ))
