import { yupResolver } from "@hookform/resolvers/yup";
import { InputField } from "../form"
import { useForm } from "react-hook-form";
import { useContactVendorSchema } from "../../pages/listing-details/schema";
import { Button, Typography } from "..";
import { Info } from "react-feather";
import { useContactVendorMutation } from "../../pages/listing-details/query";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Loader } from "../loader";
import { toastUtil } from "../../utils";
import { ContactDetails } from "./contact-details";
import { TermsAndCoditionLink } from "../terms-and-conditions-link";
import { ROUTE_PATHS } from "../../constants";

export const ContactForm = ({ vendor, isPreviewing }) => {

  const { schema } = useContactVendorSchema();

  const [showContact, setShowContact] = useState(false);

  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { agreedToTerms: false }
  });

  const { mutate, isLoading } = useContactVendorMutation()

  const onSubmit = (values) => {
    mutate({...values, vendorId: vendor.id }, {
      onError: () => {
          toastUtil({ message: 'We could not get vendors contact', theme: 'failure'})
      },
      onSuccess: () => {
        setShowContact(true);
      }
    })
  }

  if (isLoading) {
    return <div className="h-max py-6 rounded-lg mt-6 flex justify-center pb-4 bg-white">
            <Loader size="50"/>
          </div>
  }

  const disabled = isPreviewing || isLoading
  
  return (
    <form className='h-max rounded-lg mt-6 pb-4 bg-white' onSubmit={handleSubmit(onSubmit)}>
        <Typography
            translationKey={showContact ? 'VENDOR.CONTACT_FORM.VENDORS_CONTACT' : 'VENDOR.CONTACT_FORM.CONTACT_VENDOR'}
            className='py-4 px-3'
            variant='h3'
          />

      {showContact && (
        <ContactDetails vendor={vendor}/>
        )}

      {
        vendor.verified && !showContact && (
          <div className="flex bg-gray-600 px-4 py-3">
            <Info size={40} className="mt-1.5 mr-3 text-gold-300"/>
            <Typography
              translationKey='VENDOR.CONTACT_FORM.MESSAGE_TO_CLIENTS'
              className='py-4 text-white'
              variant='span'
            />
        </div>
        )
      }

      {
        !vendor.verified && !showContact && (
          <>
            <div className="flex px-4 py-3">
              <Info size={40} className="mt-1.5 mr-3 text-red-300"/>
              <Typography
                translationKey='VENDOR.CONTACT_FORM.NOT_VERIFIED_VENDOR'
                className='py-4 text-red-300'
                variant='span'
              />
            </div>
            <Link to={'/' + ROUTE_PATHS.LISTINGS}>
              <Button
                asChild
                translationKey="VENDOR.CONTACT_FORM.VIEW_VENDORS"
                className='w-[80%] mt-2 mx-auto mt-4'
                variant='link'
                size='lg'
                type='submit'
                disabled={disabled}
              />
            </Link>
          
          </>
        )
      }
      
      {vendor.verified && !showContact && 
      <>
        <div className="border border-gray-200 h-[1px] mt-6"/>
        <div className="px-8">

        <InputField
          register={{...register('email')}}
          label="VENDOR.CONTACT_FORM.EMAIL"
          className="py-4"
          placeholder="Your email"
          inputClassName='bg-gray-50'
          disabled={disabled}
          error={errors?.email?.message}
          required
        />
        <InputField
          register={{...register('firstName')}}
          label="VENDOR.CONTACT_FORM.FIRSTNAME"
          className="py-4"
          placeholder="First name here"
          disabled={disabled}
          inputClassName='bg-gray-50'
          error={errors?.firstName?.message}
          required
        />
        <InputField
          register={{...register('lastName')}}
          label="VENDOR.CONTACT_FORM.LASTNAME"
          className="py-4"
          disabled={disabled}
          inputClassName='bg-gray-50'
          placeholder="Last name here"
          error={errors?.lastName?.message}
          required
        />
        {!isPreviewing && <TermsAndCoditionLink register={register} errors={errors} disabled={disabled}/>}
        <Button
            translationKey="VENDOR.CONTACT_FORM.SHOW_VENDOR_CONTACT"
            className='w-full mt-2'
            variant='primary'
            size='lg'
            type='submit'
            disabled={disabled}
          />
        </div>
      </>
      }
    </form>
  ) 
}