import { useQuery } from 'react-query'
import {apiRoutes, axiosInstance} from '../../api'

export const useServices = () => {

  return useQuery({
    queryKey: ['services'],
    queryFn: async () => {
      const { data } = await axiosInstance.get(apiRoutes.services)

      return data;
    },
    retry: false
  })
}
