import { useState } from "react";
import { Button } from "../../../component";
import { MAX_FILE_SIZE } from "../../../constants";
import { openInputFile, toastUtil } from "../../../utils";
import { useUpdateVendorLogo } from "../query";
import { Edit2 } from "react-feather";

export const ProfileLogo = ({ vendor }) => { 
  const { mutate, isLoading, invalidateCache } = useUpdateVendorLogo()
  const [newImageSrc, setNewImageSrc] = useState()

const onFilesChange = (file) => {

  if (file?.size > MAX_FILE_SIZE) {
   return toastUtil({ message: 'File size too large - Limit is 10MB', theme: 'failure', duration: 3000 })
  }

  const reader = new FileReader()

  reader.onloadend = () => {
    const result = reader.result
    setNewImageSrc(result)
  }
  reader.readAsDataURL(file)
}

const updateLogo = () => {
  const base64String = newImageSrc.replace('data:', '').replace(/^.+,/, '')

  mutate({ id: vendor.id, logo: base64String }, {
    onSuccess: () => {
      toastUtil({message: 'Logo updated', theme: 'success', position: 'top-center'})
      setNewImageSrc(undefined)
      invalidateCache(vendor.id)
    },
    onError: (error) => {
      toastUtil({message: error.response.data.message, position: 'top-center', theme: 'failure'})
    }
  })
}

  return (
      <div className="flex justify-center mt-5 sm:mt-0 items-center flex-col">
          
          <div className="relative group">
           { !newImageSrc && <div onClick={() => openInputFile(onFilesChange)} className="cursor-pointer group-hover:visible invisible absolute top-0 bottom-0 bg-white flex justify-center items-center opacity-60 w-full h-full">
              <Edit2 size={16} fill="black"/>
            </div>}
          <img src={newImageSrc ?? vendor?.logo} alt="logo" className="border md:h-[100px] md:w-[100px] h-[80px] w-[80px] rounded-full" />
          </div>
         
         {newImageSrc && <div className="flex mt-4">
              <Button
                translationKey="VENDOR.SAVE_NEW_LOGO"
                size='md'
                variant='secondary'
                className='h-9 md:mt-0 mt-4 md:ml-3'
                onClick={updateLogo}
                isLoading={isLoading}
                disabled={isLoading}
              />
              <Button
                translationKey="VENDOR.CANCEL"
                size='md'
                variant='destructivePrimary'
                className='h-9 md:mt-0 mt-4 md:ml-3'
                disabled={isLoading}
                onClick={() => setNewImageSrc(undefined)}
              />
          </div>}
          
      </div>
)
}