import { useMutation } from 'react-query'
import { apiRoutes, axiosInstance } from '../../../api';

export const usePostReviewVendorMutation = () => {

  return useMutation(async ({slug, reviewLink, payload} ) => {
    const url = `${apiRoutes.vendors}/${slug}/${reviewLink}/review`
      const { data } = await axiosInstance.post(url, { ...payload })
      return data;
    },
  )
}
