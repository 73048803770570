import { Card } from "../card"

export const Listing = ({ vendors = []}) => {

  return (
    <div className="w-full h-full justify-center flex ">
        <div className="grid md:grid-cols-2 md:grid-flow-row w-full md:px-0 px-4 md:w-auto gap-4 max-w-[1200px]">
           { vendors?.map(vendor => <Card showDescription vendor={vendor} key={vendor.id}/>)}      
        </div>
    </div>
  )
}