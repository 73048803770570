import { Typography } from "../../component"
import vendor from '../../assets/images/vendor.jpg'
import { AnimationWrapper } from "../../component/animation-wrapper"

export const About = () => {
  return  (
    <AnimationWrapper>
      <div className="mb-4 mt-10 container mx-auto relative px-6">
        <div className="flex flex-col lg:flex-row">
        <Typography
            translationKey="ABOUT_US.LABEL"
            variant="h1"
            className="text-4xl lg:text-8xl mb-6 text-center lg:hidden"
          />
          <img src={vendor} alt="About Tradeskonnect" className="rounded-lg max-h-[250px] px-6 lg:px-0 mb-6 xl:mb-0  xl:max-h-[500px]"/>
          <div className="flex flex-col xl:ml-12 px-6 xl:px-0">
            <Typography
            translationKey="ABOUT_US.LABEL"
            variant="h1"
            className="text-2xl mb-6 xl:text-6xl text-gray-600 lg:block hidden"
          />
              <div className="flex flex-col">
                <Typography variant='h3' className="mb-6 font-normal text-md" translationKey="ABOUT_US.DESCRIPTION_ONE"/>
                <Typography  variant='h3' className="font-normal text-md" translationKey="ABOUT_US.DESCRIPTION_TWO"/>
              </div>
          </div>
        </div>
      </div>
    </AnimationWrapper>
  )
}