import { Search } from "react-feather"
import { Typography } from "../typography"
import { Button } from "../button"

export const NoResult = ({ onBtnClick, btnText = 'RESET_SEARCH' }) => {
  return (
    <div className="flex justify-center flex-col h-[300px] items-center rounded-lg w-full">
      <Search size={100} className="text-gray-300"/>
      <Typography
        variant='h3'
        className='mt-3 text-center'
        translationKey="NO_RESULT_ONE"
      />
      <Button
        translationKey={btnText}
        size='lg'
        className='mt-4'
        onClick={onBtnClick}
        variant='destructivePrimary'
      />
    </div>
  )
}