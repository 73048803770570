import { pdfjs } from 'react-pdf';
import { useState, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Button } from '../button';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url


export function PdfDocument({ file }) {
  const [pageIndex, setPageIndex] = useState(0)
  const [numPages, setNumPages] = useState()
  const [isMobile, setIsMobile] = useState()
  const [pageSize, setPageSize] = useState(0)
  const ref = useRef()
  const eventHandler = (e => {
    if (e.target.innerWidth < 1000) {
      setPageSize(e.target.innerWidth - 50)
    } else {
      setPageSize(750)
    }
  })

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  useEffect(() => {
    const small = window.innerWidth < 1000
    setIsMobile(small)
    setPageSize(window.innerWidth > 1000 ? 850 : window.innerWidth - (window.innerWidth * 0.1))
    window.addEventListener('resize', eventHandler)
  }, [])
  

  const updatePage = (previous = false) => {
      if (previous && pageIndex !== 0) {
        setPageIndex(pageIndex - 1)
        !isMobile && ref.current.scrollIntoView({behavior: "smooth"})
        return
      }
      
    if (!previous && pageIndex !== numPages - 1) {
    setPageIndex(pageIndex + 1 )
    !isMobile && ref.current.scrollIntoView({behavior: "smooth"})
      }
  }

  return (
    <section ref={ref} className="px-10 flex justify-center container mx-auto">
      <section className='md:h-auto h-screen'>
      <Document
        file={file}
        onLoadSuccess={onLoadSuccess}
      >
        {<Page width={pageSize} pageIndex={pageIndex} />}
        </Document>
        <section className='flex justify-end p-5'>
            {pageIndex > 0 && (
                <Button
                translationKey='Previous'
                variant='secondary'
                size="sm"
                className="mr-6"
                onClick={() =>updatePage(true)}
              />
            )}
            {pageIndex !== numPages - 1 &&
              <Button
              translationKey='Next page'
              variant='primary'
              size="sm"
              onClick={() => updatePage()}
            />
            }
          </section>
      </section>
    </section>
      );
}

// import { useEffect, useState } from 'react';

// const Privacy = () => {
//   const [pageIndex, setPageIndex] = useState(0)
//   const [numPages, setNumPages] = useState()
//   const [pageSize, setPageSize] = useState(0)

//   const eventHandler = (e => {
//     if (e.target.innerWidth < 1000) {
//       setPageSize(e.target.innerWidth - 50)
//     } else {
//       setPageSize(750)
//     }
//   })
//   useEffect(() => {
//     setPageSize(window.innerWidth > 1000 ? 750 : window.innerWidth - (window.innerWidth * 0.1))
//     window.addEventListener('resize', eventHandler)
//   }, [])
//   const onLoadSuccess = ({ numPages }) => {
//     setNumPages(numPages)
//   }

//   const updatePage = (previous = false) => {
//       if (previous && pageIndex !== 0) {
//         setPageIndex(pageIndex - 1)
//         return
//       }
      
//     if (!previous && pageIndex !== numPages - 1) {
//     setPageIndex(pageIndex + 1 )
//       }
//   }

//   return (
//     <div className='main-content-wrapper'>
//       <Header/>
//       <section className="main-content">
//        <div className="right">
//             <section className='document'>
//                 <div className='flex items-center justify-between'>
//                     <h4 className='font-bold text-3xl'>Privacy Policy</h4>
//                     <section className='buttons'>
//                         {pageIndex > 0 && <button onClick={() =>updatePage(true)}>
//                           <ArrowLeft size={16}/>
//                           </button>}
//                     {pageIndex !== numPages - 1 && <button className='first-button' onClick={() => updatePage()}>
//                       <ArrowRight size={16}/>
//                       </button>}
//                     </section>
//                 </div>
//             {/* <Document
//               file={pdf}
//               onLoadSuccess={onLoadSuccess}
//             >
//               {<Page width={pageSize} pageIndex={pageIndex} />}
//             </Document> */}

//         <Document className="w-[200px]" file={privacyFile} onLoadSuccess={onDocumentLoadSuccess}>
//         {<Page width={pageSize} pageIndex={pageIndex} />}
//               </Document>

//               </section>
//         <div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// }

// export default Privacy;