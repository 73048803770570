import { useMutation } from 'react-query'
import { apiRoutes, axiosInstance } from '../../../api'



export const useForgotPasswordMutation = () => {
  
  return useMutation(async ({ email }) => {
      const { data } = await axiosInstance.post(apiRoutes.forgotPassword, { email })
      return data
    })
}