import { RouteWrapper } from "../../common"
import { useAuth } from "../../core";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ROUTE_PATHS } from "../../constants";

export const AdminWrapper = ({children }) => {
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth?.isLoading && !auth?.isAuthenticated) { 
      return navigate(ROUTE_PATHS.HOME)
    }

  }, [auth?.isAuthenticated, auth?.isLoading,auth?.isAdmin, navigate])

  return (
    <RouteWrapper>
        {children}
    </RouteWrapper>
)
}