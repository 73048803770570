import { Typography } from "../../../component"

export const Header = ({ text }) => {
  return <div className="container mx-auto mt-12">
    <Typography
			translationKey={text}
			variant="h3"
    />
		<div className="h-0.5 bg-gray-200 mt-2 w-full"/>
  </div>
}