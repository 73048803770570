import { useMutation } from 'react-query';
import { apiRoutes, axiosInstance } from '../../api';


export const useSendFeedback = () => {

  return useMutation(async (details) => {
      const { data } = await axiosInstance.post(apiRoutes.feedback, {...details})
      return data;
    },
  )
}