
import React from 'react';
import ReactStars from "react-rating-stars-component";

import { InputField, Typography } from '../../component';
import { useFeedbackSchema } from './schema';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useSendFeedback } from './query';
import { toastUtil } from '../../utils';


export const FeedbackForm = () => {
	const schema = useFeedbackSchema()
	const { mutate, isLoading } = useSendFeedback()
	const { handleSubmit, formState: { errors, isValid }, reset, watch, register, control } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { reviewComment: '', starCount: 0 }
	})
	
  
const onSubmit = (values) => {

	mutate({
		rating: values.starCount,
		comment: values.reviewComment
	}, {
		onSuccess: () => {
			toastUtil({message: 'Thank you for leaving a feedback', theme: 'success'})
			reset({
				reviewComment: '',
				starCount: 0
			})
		},
		onError: () => toastUtil({message: 'We could not save your feedback. Please try again', theme: 'failure'})
	})
	
}

	const starCount = watch('starCount')
	const reviewComment = watch('reviewComment')

  return (
  <div className='md:px-0 flex flex-col md:items-start items-center'>
		<Typography
			translationKey="FOOTER.LEAVE_REVIEW_TITLE"
			variant="h3"
			className="text-white"
		/>
    <form onSubmit={handleSubmit(onSubmit)} className='container flex flex-col mx-auto my-2 sm:min-w-[250px] max-w-[300px]'>
			<div className='flex flex-col items-center md:items-start'>
				<Controller
					name="starCount"
					control={control}
					defaultValue={starCount}
					render={({ field }) => {
						return (
							<ReactStars
							count={5}
							value={starCount}
							size={20}
							half
							color="gray"
							color2={'#ffd700'}
							{...field}
						/>
						)
					}}
				/>
			</div>
			<InputField
				register={{...register('reviewComment')}}
				label="FOOTER.REVIEW_DESCRIPTION"
				className="py-1"
				labelClassName='text-white text-xs'
				inputClassName='bg-gray-50'
				error={errors?.reviewComment?.message}
				type='textarea'
				textareaValue={reviewComment}
			/>

				<InputField 
				disabled={!isValid || isLoading}
				isLoading={isLoading}
				type="submit" 
				inputClassName={classNames('w-full mt-2 md:w-auto ml-auto text-white px-4 h-12 rounded-lg', { 'bg-gray-500': !isValid, 'bg-red-500': isValid})} label='FOOTER.SUBMIT_REVIEW'
			/>
		</form>
  </div>
  )
}