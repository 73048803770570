import { Award, CheckCircle } from "react-feather";
import { AnimationWrapper, Button, Typography } from "../../component";
import { ListingForm } from "./form";
import { useState } from "react";
import { usePostListing } from "./query";
import { useNavigate } from "react-router-dom";

export const AddListing = () => {
  const [listingCreated, setListingCreated] = useState()
  const navigate = useNavigate()
  const { mutate, isLoading: isMutationLoading } = usePostListing()
  

  return (
    <AnimationWrapper>

    <div className="min-h-screen bg-gray-100 pb-6">
      <div className="h-[200px] bg-home-background bg-center md:bg-right">
        <div className="h-full bg-opacity-60 bg-blue-500 flex justify-center items-center bg-center">
          <div>
            <Typography
              translationKey="ADD_LISTING.LABEL"
              variant="h1"
              className="text-white text-2xl md:text-4xl text-center mb-8"
            />
            <Typography
              translationKey="ADD_LISTING.DESCRIPTION"
              variant="h1"
              className="text-white text-md md:text-2xl text-center mb-8"
            />
          </div>
        </div>
      </div>
      {
        <div className={"md:w-7/12 w-11/12 mt-10 mb-5 py-4 rounded-lg border border-neutral-100 shadow bg-white h-auto mx-auto"}>
         {!listingCreated && <div className="h-full">
              <div className="flex items-center px-6 py-5">
                <Award size={18} className="mr-1"/>
                <Typography
                  translationKey="ADD_LISTING.TITLE"
                  variant="h3"
                  className="font-semibold"
                />
              </div>

              <div className="border border-gray-200 h-[1px]"/>
                <ListingForm
                  className="px-7"
                  footerClassName="px-7"
                  mutateFn={mutate}
                  onSuccess={() => setListingCreated(true)}
                  mutationLoading={isMutationLoading}  />
          </div>}

          {listingCreated && <div className="h-full flex justify-center">
              <div className="flex items-center flex-col px-6 py-5">
                <Typography
                  variant='h2'
                  className='px-6 mb-4'
                  translationKey="ADD_LISTING.ACCOUNT_CREATED"
                />
                <CheckCircle  size={50} className="text-green-200" />
                <Typography
                  variant='h5'
                  className='px-6 mt-4 text-center'
                  translationKey="ADD_LISTING.ACCOUNT_CREATED_MESSAGE"
                />
                <Button onClick={() => navigate('/login')} translationKey="ADD_LISTING.LOGIN" className='p-3 bg-red-600 text-white rounded-lg mt-3'/>
              </div>
          </div>}
        </div>
      }
    </div>
    </AnimationWrapper>
  );
};
