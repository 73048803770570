import { useMutation, useQueryClient } from 'react-query'
import {baseApi, apiRoutes, axiosInstance} from '../../../api'

export const usePostImage = () => {

    const query = useQueryClient()
  const mutation = useMutation(
    async ({ vendorId, imageUrl, publicUrl }) => {
        const { data } = await axiosInstance.post(baseApi + apiRoutes.vendors + `/${vendorId}/upload`, { imageUrl, publicUrl })

      return data;
    }
  )

  const invalidateCache = (vendorId) => {
    return query.invalidateQueries({queryKey: ['auth-vendor-details', vendorId]})
  }

  return { ...mutation, invalidateCache }
}

