import { Link } from "react-router-dom";
import { Typography } from "../../component";
import tradesKonnectLogo from '../../assets/images/tradeskonnect.png';
import classNames from "classnames";

export const TradesKonnectLogo = ({ className, imageClassName }) => {

  return (
    <Link to="/">
      <div className={"font-semibold text-xl flex items-center text-blue-600"}>
      <img src={tradesKonnectLogo} alt="TradesKonnect" className={classNames("h-[50px]", imageClassName)}/>
        <Typography
        translationKey="TRADES_KONNECT"
        className={classNames('md:hidden text-center ml-3', className)}
        variant='h3'
        />
      </div>
    </Link>
  );
};
